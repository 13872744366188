import React, { memo } from 'react'
import SwitchExample from '../switchbuttons/SwitchButton'
import { BServer } from '../../../../server/backend'
import { useNavigate } from 'react-router-dom'
const MyTableRow = props => {
    const { tableBody, click, clickFunc, userData } = props
    const navigate = useNavigate()
    console.log('userData', userData)
    const declareType = (each, data) => {
        if (each === "isActive" || each === "verification") {
            return <SwitchExample disabled={!userData['profileId._id']} />
        }
        else if (each === "profileId.personalInformation.profilePicture") {
            console.log(data)
            return <img
                src={`${BServer}/uploads/${data}`} alt="Md Mudassar"
                style={{ width: 40, height: 40, cursor: !userData['profileId._id'] ? 'not-allowed' : 'pointer' }}
                onClick={() => userData['profileId._id'] && window.open(`/admin/profile-${userData?.userRole}/${encodeURIComponent(JSON.stringify(userData?._id))}`, '_blank')}
            />

        }
        else if (each === "profileId.personalInformation.resume") {
            return <a href={`${BServer}/uploads/${data}`} target='_blank'>view</a>
        }
        else {
            return data
        }
    }


    return (
        <tr className={!userData['profileId._id'] && `bg-danger`}>
            {tableBody.map(each => {
                return (
                    click === each ?
                        <td style={{cursor:'pointer !important'}}  onClick={clickFunc.bind(null, userData)} >
                            {declareType(each, userData[each])}
                        </td>
                        :
                        <td style={{cursor:'pointer !important'}}  >
                            {declareType(each, userData[each])}
                        </td>
                )
            })}
        </tr>
    )
}

export default memo(MyTableRow)
