import * as Yup from 'yup'
import {nameValueAdmin} from '../../initialValues/createProfile'
import { isOver18 } from './devProfileValidation'
export const adminProfileValidation =Yup.object().shape({

    [nameValueAdmin.fullName]:Yup.string().required(' Name is mandatory'),


    [nameValueAdmin.dateOfBirth]:Yup.string()
    .test('','Age should min 18 years',(value)=>isOver18(value))
    .required('Dob is mandatory'),

    [nameValueAdmin.gender]:Yup.object().required('Gender is mandatory'),

    [nameValueAdmin.profilePicture]:Yup.string().required('Profile picture is mandatory'),


    // [nameValueAdmin.email]: Yup
    // .string()
    // .email('email is invalid')
    // .test('email is invalid', (value) => {
    //     return value.split('@')[1]?.includes('.')
    // })
    // .required('email is required'),

    
    [nameValueAdmin.currentLocation_address]:Yup.string().required('Address is mandatory'),

    [nameValueAdmin.currentLocation_country]:Yup.object().test('','Country is invalid',(value)=>Object.keys(value)?.length).required('Country is mandatory'),

    [nameValueAdmin.currentLocation_state]:Yup.object().test('','State is invalid',(value)=>Object.keys(value)?.length).required('State is mandatory'),

    [nameValueAdmin.currentLocation_district]:Yup.object().test('','District is invalid',(value)=>Object.keys(value)?.length).required('District is mandatory'),

    [nameValueAdmin.currentLocation_city]:Yup.object().test('','City is invalid',(value)=>Object.keys(value)?.length).required('City is mandatory'),

    [nameValueAdmin.currentLocation_pinCode]:Yup.string().required('Pincode is mandatory'),

    
    [nameValueAdmin.professionalExperience_currentDesignation]:Yup.string().required('Designation is mandatory'),


    [nameValueAdmin.social_linkedin]:Yup.string().test('','Invalid url',(value)=>value.includes('linkedin.com')).required('Linkedin is mandatory'),


})


