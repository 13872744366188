import * as Yup from 'yup'
import { nameValueAgency } from '../../initialValues/createProfile'
export const agencyProfileValidation = Yup.object().shape({

    [nameValueAgency.profilePicture]: Yup.string().required('Profile picture is mandatory'),


    [nameValueAgency.fullName]: Yup.string().required(' Company name is mandatory'),

    [nameValueAgency.description]: Yup.string().test('', 'Description must be within 200 characters', (value) => value.length <= 200).required(' Description is mandatory'),

    [nameValueAgency.location_address]: Yup.string('Not valid').required('Address is mandatory'),

    [nameValueAgency.overview_areaOfExpertise]: Yup.array().test('must', 'Select area of expertise', (value => value.length > 0)).required('Area of Expertise is mandatory'),

    [nameValueAgency.overview_companySize]: Yup.object().test('', 'Select company size', (value) => Object.keys(value)?.length).required('Company Size is mandatory'),

    [nameValueAgency.overview_industrialExperience]: Yup.number().test('', 'Must be positive', (value) => value >= 0).test('', 'Must be less than 100', (value) => value < 100).required('Industrial Experience is mandatory'),


    [nameValueAgency.social_linkedin]: Yup.string().test('', 'Invalid url', (value) => value.includes('linkedin.com')).required('Linkedin is mandatory'),

    [nameValueAgency.social_website]: Yup.string().required('Company Website is mandatory'),

    [nameValueAgency.location_country]: Yup.object().test('', 'Select country', (value) => Object.keys(value)?.length).required('Country is mandatory'),

    [nameValueAgency.location_state]: Yup.object().test('', 'Select state', (value) => Object.keys(value)?.length).required('State is mandatory'),

    [nameValueAgency.location_district]: Yup.object().test('', 'Select district', (value) => Object.keys(value)?.length).required('District is mandatory'),

    [nameValueAgency.location_city]: Yup.object().test('', 'Select city', (value) => Object.keys(value)?.length).required('City is mandatory'),

    [nameValueAgency.location_pinCode]: Yup.string().required('Pincode is mandatory'),

    [nameValueAgency.documents_registration]: Yup.string().required('Registration is mandatory'),

    [nameValueAgency.documents_panCard]: Yup.string().required('Pan Card is mandatory'),

    [nameValueAgency.documents_gstCertificate]: Yup.string().required('Gst is mandatory'),

    [nameValueAgency.assignVerificationTo]: Yup.object().test('', 'Select authenticate person', (value) => Object.keys(value)?.length).required('Authenticate person is mandatory'),
})



