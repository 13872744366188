import *as Yup from 'yup'


export const CreateAdminvalidation = (userRole) => {
    return Yup.object({
        email: Yup
            .string()
            .email('Email is invalid')
            .test('is-not-free-email', 'Please register with valid work email.', function (value) {
                // List of free email domains to exclude
                const excludeDomains = ['carivatechnologies.com','talnfinium.cloud'];

                // Extract the domain from the email address
                const domain = (value || '').split('@')[1];

                // Check if the domain is not in the excluded list

                // it should !excludeDomains
                return excludeDomains.includes(domain);


            })
            .test('Email is invalid', (value) => {
                return value.split('@')[1]?.includes('.')
            })
            .required('Email is required'),
    })
}

// ([/@gmail\.com/, /yahoo\.com/, null])


