import React from 'react'
import styles from "./EmailVerificationModal.module.css"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useSelector } from "react-redux"
import axios from 'axios'
import { BServer } from '../../../../../server/backend'
import { IoMdClose } from "react-icons/io";
import { useDispatch } from "react-redux"
import { globalModal } from '../../../../../Redux/actions/globalModal'
import { toast } from 'react-toastify'

export default function EmailVerificationModal() {
    const dispatch = useDispatch();
    const authecationState = useSelector((state) => state.authentication)
    const isToken = (authecationState.token === "") ? false : true;   // if token exists, user has already logged in
    const { values, handleChange, handleSubmit, resetForm, errors } = useFormik({
        initialValues: {
            email: authecationState.userEmail,
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Email is required'),
        }),
        onSubmit: values => {

            axios.post(`${BServer}/sentUrlOnEmail`, {
                email: values.email,
            }).then(response => {

                if (response?.status==200){
                    setTimeout(() => {
                            dispatch(
                              globalModal({
                                component: "ContactFormModal",
                                closeButton: true,
                                size: "md",
                                data:{message:response?.data?.data?.message}
                              })
                            );
                         
                    }, 1000);
                }else {
                    toast.error(response?.data?.message)
                }

            }).catch(error => {
                console.log(error);
            });
        }
    });



    return (
        <form onSubmit={handleSubmit} >
            <div className={styles.emailverificationcard} >
                <span className={styles.closeIcon} onClick={() => dispatch({ type: 'HIDE_MODAL' })} ><IoMdClose size={"24px"} /></span>
                <h1>Provide your registered email</h1>
                <div className={styles.inputclass}>
                    <label htmlFor="email">Enter Your Email: </label>
                    <input type='text' id='email' name='email' defaultValue={values.email} onChange={handleChange} disabled={isToken} /><br />
                    {errors.email && <span>
                        {errors?.email}
                    </span>}
                </div>
                <button type='submit'style={{background:'#18042B'}} >Submit</button>
            </div>
        </form>
    )
}
