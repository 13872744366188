import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Hello from './components/hello/Hello';
import { globalModalComponents } from './globalModalComponents';
import styles from "./GlobalModal.module.css"
import { IoMdClose } from "react-icons/io";

const GlobalModal = ({ children }) => {
    const store = useSelector((state) => state?.globalModal)
    // console.log(store)
    const dispatch = useDispatch()
    // console.log('12', store.component)
    return (
        // <>
        //     {children && children}
        //     {store?.show &&

        //         <div className={styles.ModalBackdrop}>
        //             <div className={styles.ModalContent}>
        //                 {globalModalComponents[store.component]}
        //             </div>
        //         </div>

        //     }


        // </>

        <>
            {children && children}
            {store?.show &&
                <Modal
                    show={store?.show}
                    size={store?.size ? store.size : 'md'}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered

                    // onHide={store.closeButton ?() => dispatch({ type: 'HIDE_MODAL' }):null}


                    backdrop="static"
                    keyboard={false}
                >
                    {/* <Modal.Header
                     closeButton
                    >
                        <Modal.Title id="contained-modal-title-vcenter">
                            Modal heading
                        </Modal.Title>
                    </Modal.Header> */}

                    <Modal.Body >
                        {
                            store?.closeButton &&
                            <span onClick={() => dispatch({ type: "HIDE_MODAL" })} style={{ float: 'right', cursor: 'pointer' }}>
                                <IoMdClose size={"25px"} />
                            </span>
                        }

                        {
                            globalModalComponents[store.component]
                        }

                        {/* <h4>Centered Modal</h4>
                        <p>
                            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                            consectetur ac, vestibulum at eros.
                        </p> */}
                    </Modal.Body>
                    {/* {
                        store?.closeButton &&
                        <Modal.Footer>
                            <Button onClick={() => dispatch({ type: 'HIDE_MODAL' })} style={{ backgroundColor: '#18042B', color: '#fff' }}>Close</Button>
                        </Modal.Footer>
                    } */}
                </Modal>

            }
        </>

    )
}


export default GlobalModal