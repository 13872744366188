import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "./CustomDropdown.css";
const CustomDropdown = (props) => {
  const { toggleText, items } = props;

  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic" className="dropdown_background">
        {toggleText}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {items.map((item, index) => (
          <Dropdown.Item key={index} href={`#/${item}`}>
            {item}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomDropdown;
