import React, { useState } from "react";
import * as Yup from "yup";
import styles from "./ContactForm.module.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { IoMdClose } from "react-icons/io";
import { useDispatch } from "react-redux";
import CascadingDropdown from "../../../../../pages/forDeveloper/profilePage/createProfile/CascadingDropdown";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { contactFormAction } from "../../../../../Redux/actions/hireDeveloper/contactFormAction";
import { globalModal } from "../../../../../Redux/actions/globalModal";

const ContactForm = () => {
  const dispatch = useDispatch();
  const [btnTxt, setBtnTxt] = useState("Submit");
  const DomaiOfExpertiseOptions = useSelector(
    (state) => state?.formDropdown?.domainOfExpertise
  );
  const [responseMessage, setResponseMessage] = useState("");
  const {
    values,
    handleChange,
    handleSubmit,
    resetForm,
    errors,
    setFieldValue,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: null,
      companyName: "",
      domaiOfExpertise: [],
      description: "",
      tandc: false,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .test(
          "",
          "First name should be between 3 to 10 characters",
          (value) => value.length >= 3 && value.length <= 10
        )
        .required(),

      lastName: Yup.string()
        .test(
          "",
          "Last name should be between 3 to 10 characters",
          (value) => value.length >= 3 && value.length <= 10
        )
        .required(),

      email: Yup.string()
        .email("Email is invalid")
        .test("Email is invalid", (value) => {
          return value.split("@")[1]?.includes(".");
        })
        .required("Email is required"),

      // mobile: Yup.string().test('', 'Mobile number length should be 10 ', (value) => value.length=== 10).required(),
      mobile: Yup.string()
        .matches(/^\d+$/, "Mobile number must be numeric")
        .length(10, "Mobile number length should be 10")
        .required("Mobile number is required"),

      companyName: Yup.string()
        .test(
          "",
          "Company name should be between 3 to 30 characters",
          (value) => value.length >= 3 && value.length <= 30
        )
        .required(),

      // domaiOfExpertise: Yup.object().test('', 'Invalid domain of expertise', (value) => Object.keys(value)?.length).required('Domain of expertise is mandatory'),

      domaiOfExpertise: Yup.array()
        .test("must", "Select min 1 Domain", (value) => value.length >= 1)
        .test("must", "Select max 5 domain", (value) => value.length <= 5)
        .required("Domain is mandatory"),

      description: Yup.string()
        .test(
          "",
          "Description should be between 20 to 1000 character",
          (value) => value.length >= 20 && value.length <= 1000
        )
        .required("Description is mandatory"),
      tandc: Yup.boolean().oneOf([true], "Please accept Terms and Conditions"),
    }),
    enableReinitialize: true,

    // userIdFromUseLocation will only be provided with dispatch when agency try to edit there developer
    onSubmit: (values) => {
      dispatch(
        contactFormAction(values, (props) => {
          setBtnTxt("Submiting...");
          // setResponseMessage(props);
          // setTimeout(() => {
          //   setBtnTxt("Submited");
          //   dispatch(
          //     globalModal({
          //       component: "ContactFormModal",
          //       closeButton: true,
          //       size: "md",
          //     })
          //   );
          // }, 1500);
        })
      );
    },
  });

  return (
    <div className={styles.mainformbody}>
      <div className={styles.closebutton} onClick={() => dispatch({ type: 'HIDE_MODAL' })} ><IoMdClose size={"24px"} /></div>
      <Form>
        <div className={styles.contact_us}>
          {/* This span uses to center the heading dynamically*/}
          <span></span>
          <p>Contact Us</p>
          {/* {responseMessage && <p>{responseMessage}</p>} */}
          {/* <span onClick={() => dispatch({ type: "HIDE_MODAL" })}>
            <IoMdClose size={"25px"} />
          </span> */}
        </div>
        <div className={styles.DISPLAYFLEXCONT}>
          <Form.Group
            className={styles.formInput}
            controlId="formBasicPassword"
          >
            <Form.Label style={{ fontSize: "15px", color: "black" }}>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your First name"
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.firstName && (
              <span className={styles.errorFeild}>{errors.firstName}</span>
            )}
          </Form.Group>
          <Form.Group
            className={styles.formInput}
            controlId="formBasicPassword"
          >
            <Form.Label style={{ fontSize: "15px", color: "black" }}>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your last name"
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.lastName && (
              <span className={styles.errorFeild}>{errors.lastName}</span>
            )}
          </Form.Group>
        </div>
        <div className={styles.DISPLAYFLEXCONT}>
          <Form.Group className={styles.formInput} controlId="formBasicEmail">
            <Form.Label style={{ fontSize: "15px", color: "black" }}>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.email && (
              <span className={styles.errorFeild}>{errors.email}</span>
            )}{" "}
            <br />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
          <Form.Group
            className={styles.formInput}
            controlId="formBasicPassword"
          >
            <Form.Label style={{ fontSize: "15px", color: "black" }}>Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your Mobile Number"
              name="mobile"
              value={values.mobile}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.mobile && (
              <span className={styles.errorFeild}>{errors.mobile}</span>
            )}
          </Form.Group>
        </div>
        <div className={styles.DISPLAYFLEXCONT}>
          <Form.Group
            className={styles.formInput}
            controlId="formBasicPassword"
          >
            <Form.Label style={{ fontSize: "15px", color: "black" }}>Company Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your Company name"
              name="companyName"
              value={values.companyName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.companyName && (
              <span className={styles.errorFeild}>{errors.companyName}</span>
            )}
          </Form.Group>
          <Form.Group
            className={styles.formInput}
            controlId="formBasicPassword"
          >
            <CascadingDropdown
              label={"Domain of Expertise"}
              options={DomaiOfExpertiseOptions}
              required={true}
              setFieldValue={setFieldValue}
              name="domaiOfExpertise"
              onBlur={handleBlur}
              error={touched.domaiOfExpertise && errors.domaiOfExpertise}
              value={values?.domaiOfExpertise}
            />
            {/* {errors.domaiOfExpertise && (
              <span className={styles.errorFeild}>
                {errors.domaiOfExpertise}
              </span>
            )} */}
          </Form.Group>
        </div>
        <Form.Group
          className={styles.formInput}
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Label style={{ fontSize: "15px", color: "black" }}>
            What are your requirements? Please Explain briefly
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            name="description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.description && (
            <span className={styles.errorFeild}>{errors.description}</span>
          )}
        </Form.Group>
        <Form.Group className={styles.formInput} controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label="I accept the Terms & Conditions"
            style={{ fontSize: "12px", marginTop: "10px" }}
            className={styles.termandConditions}
            name="tandc"
            value={values.tandc}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.tandc && (
            <span className={styles.errorFeild}>{errors.tandc}</span>
          )}
        </Form.Group>
        <div className={styles.ContactForm_Button}>
          <Button
            type="submit"
            style={{
              backgroundColor: "#18042B",
              border: "none",
              width: "30%",
              borderRadius: "0px",
              padding: "8px 30px",
              fontSize: "15px",
            }}
            onClick={handleSubmit}
          >
            {btnTxt}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ContactForm;
