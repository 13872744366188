import React from 'react'
import styles from "./CreateProfileSubmission.module.css"
import { useDispatch } from "react-redux"


export default function CreateProfileAgencySubmission() {
    const dispatch = useDispatch()

    return (
        <div className={styles.SubmissionModal} >
            <h3>Confirmation</h3>
            <p>
                <small>You can not change once submit these:</small>
                <ol>
                    <li>Company Email <span>*</span> </li>
                    <li>Company Name <span>*</span> </li>
                    <li>Company Linkedin <span>*</span> </li>
                    <li>Company Website <span>*</span> </li>
                </ol>
                <b>
                    Do you want to submit the details?
                </b>
            </p>
            <div className={styles.SubmissionModalActions}>
                <button onClick={() => dispatch({ type: 'HIDE_MODAL' })} >Cancel</button>
                <button>Submit</button>
            </div>
        </div>
    )
}
