import React from 'react'
import './Footer.css'
const Footer = () => {
  return (
    <div className='d-flex align-items-center justify-content-center bg-dark text-light'>
        <h4>Footer</h4>
    </div>
  )
}

export default Footer