import React from 'react'
import styles from '../../../forDeveloper/profilePage/createProfile/CreateProfileDev.module.css'
const AgencyPageAssignToDropDown = ({ label, objValues, error,required, ...props }) => {
  return (
    <div className={styles.ProfilePageDropdownFeild}>
            <label htmlFor="">{label}{required && <span> *</span>}</label>
            <select  {...props} className={error && styles.errorRedBorder} >
                {objValues.map(opt => {
                    return (
                        // for accessing email use {opt.email}
                        <option value={opt} key={opt.name}>{opt.name}</option>
                    )
                })}
            </select>
            {/* {meta.touched && meta.error ? (
                <div className={styles.ProfilePageInputfield_error_msg}>{meta.error}</div>
            ) : null} */}
        </div>
  )
}

export default AgencyPageAssignToDropDown
