import React, { memo } from "react";
import { useField } from "formik"
import styles from './CreateProfileAgency.module.css'
import { BServer } from "../../../../server/backend";
import Form from 'react-bootstrap/Form';

const ProfilePageInputfeild = (props) => {
    const { label, required, name, type, accept, disabled, error,onChange,value } = props

    return (
        <>

            <div className={styles.AgencyPagefileinput}>
                 <label htmlFor={label}>
                    {label}
                    {
                        required && <span> *</span>
                    }
                </label> &nbsp;
                {value &&
                <a href={`${BServer}/uploads/${value}`} target="blank">
                    {value}
                </a>
                }
                <Form.Control type="file" accept={accept} name={name} onChange={onChange}  />
                {/* <input type="file" accept={accept} name={name} onChange={onChange} /> */}
                {
                    error &&
                    (
                        <p className={styles.AgencyPageInputfield_error_msg}>
                            {error}
                        </p>
                    )
                }
            </div>

        </>
    )
}

export default memo(ProfilePageInputfeild)