// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* body {
    margin: 0;
    padding: 0;
  }
  h2 {
    font-size: 60px;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
  } */
  .DevCarousels_slide__HUA\\+r{
    /* width: 300px; */
    background: linear-gradient(108.36deg, rgb(110 69 226 / 61%) 1.17%, rgb(211 136 136 / 12%) 100%);
    /* background: linear-gradient(108.36deg, rgb(52 25 129) 1.17%, rgb(211 136 136 / 35%) 100%); */
    /* background: linear-gradient(108.36deg, rgb(110, 69, 226) 1.17%, rgb(136, 211, 206) 100%); */
    /* background-color: transparent; */
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 10px !important;
    border: 1px solid #ffffff41;
    /* display: flex; */
    border-radius: 0px;
    padding: 25px 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    margin: 0 15px;
  }
  .DevCarousels_react__Hyc5S{
    display: flex;
    
  }
  .DevCarousels_react__Hyc5S h5{
    font-weight: 400;
    font-size: 19px;
    color: #FFF;
  }
  .DevCarousels_exp__waSL9{
    margin-top: 0px;
    margin-left: 60px;
    white-space: nowrap;
  }
  .DevCarousels_spancard__Bnd\\+6{
    border-radius: 5px !important;
    border: 0.5px solid #fff;
    color: #fff;
    margin-right: 10px;
    font-size: 13px;
    padding: 3px 7px;
    border-radius: 0px;
  }
 
  
`, "",{"version":3,"sources":["webpack://./src/pages/common/landingPage/components/Carousel/DevCarousel/DevCarousels.module.css"],"names":[],"mappings":"AACA;;;;;;;;;KASK;EACH;IACE,kBAAkB;IAClB,gGAAgG;IAChG,+FAA+F;IAC/F,8FAA8F;IAC9F,mCAAmC;IACnC,kCAA0B;YAA1B,0BAA0B;IAC1B,8BAA8B;IAC9B,2BAA2B;IAC3B,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,kCAAkC;IAClC,gBAAgB;IAChB,cAAc;EAChB;EACA;IACE,aAAa;;EAEf;EACA;IACE,gBAAgB;IAChB,eAAe;IACf,WAAW;EACb;EACA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;EACA;IACE,6BAA6B;IAC7B,wBAAwB;IACxB,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;EACpB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');\n/* body {\n    margin: 0;\n    padding: 0;\n  }\n  h2 {\n    font-size: 60px;\n    font-family: sans-serif;\n    display: flex;\n    justify-content: center;\n  } */\n  .slide{\n    /* width: 300px; */\n    background: linear-gradient(108.36deg, rgb(110 69 226 / 61%) 1.17%, rgb(211 136 136 / 12%) 100%);\n    /* background: linear-gradient(108.36deg, rgb(52 25 129) 1.17%, rgb(211 136 136 / 35%) 100%); */\n    /* background: linear-gradient(108.36deg, rgb(110, 69, 226) 1.17%, rgb(136, 211, 206) 100%); */\n    /* background-color: transparent; */\n    backdrop-filter: blur(2px);\n    border-radius: 10px !important;\n    border: 1px solid #ffffff41;\n    /* display: flex; */\n    border-radius: 0px;\n    padding: 25px 20px;\n    font-family: 'Poppins', sans-serif;\n    font-weight: 300;\n    margin: 0 15px;\n  }\n  .react{\n    display: flex;\n    \n  }\n  .react h5{\n    font-weight: 400;\n    font-size: 19px;\n    color: #FFF;\n  }\n  .exp{\n    margin-top: 0px;\n    margin-left: 60px;\n    white-space: nowrap;\n  }\n  .spancard{\n    border-radius: 5px !important;\n    border: 0.5px solid #fff;\n    color: #fff;\n    margin-right: 10px;\n    font-size: 13px;\n    padding: 3px 7px;\n    border-radius: 0px;\n  }\n \n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slide": `DevCarousels_slide__HUA+r`,
	"react": `DevCarousels_react__Hyc5S`,
	"exp": `DevCarousels_exp__waSL9`,
	"spancard": `DevCarousels_spancard__Bnd+6`
};
export default ___CSS_LOADER_EXPORT___;
