import React, { useEffect } from 'react'
import linkedin from "../../../../assets/images/linkedin.png";
import github from "../../../../assets/images/github_270798.png";
import "./ViewProfileAdmin.css";
import { useDispatch, useSelector } from 'react-redux';
import { BServer } from '../../../../server/backend';
import { useNavigate, useParams } from 'react-router-dom';
import { viewProfileAdminAction } from '../../../../Redux/actions/profile/viewProfile/viewProfileAdminAction';
const ViewProfileAdmin = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const authentication = useSelector((state) => state?.authentication)
  const { loading, profileData } = useSelector(
    (state) => params?.userId ? state?.viewProfileAdmin : state?.myProfile
  );
  const userId = params?.userId ? decodeURIComponent(JSON.parse(params?.userId)) : authentication?.userId
  console.log('profileData', profileData)
  console.log('profileData', params?.userId)

  useEffect(() => {
    dispatch(viewProfileAdminAction(userId));

  }, [])
  return (
    <div className="container-fluid whole-sec">
      <div className="main-body">
        <div className="row gutters-sm">
          <div className="col-md-4 mb-3 mt-5">
            {/* <div className="profile-card"> */}
            <div className="profile-card-body">
              <div className="d-flex flex-column align-items-center text-center">
                <img
                  src={profileData?.personalInformation?.profilePicture && `${BServer}/uploads/${profileData?.personalInformation?.profilePicture}`}
                  alt="Admin"
                  className="rounded-circle mt-3"
                  style={{ width: "7rem", height: "7rem" }}

                />
                <div className="mt-3">
                  <h4>
                    {profileData?.personalInformation?.fullName}
                  </h4>
                  <p className="cat-exper mb-3">
                    {profileData?.userId?.memberId}
                  </p>

                </div>
              </div>

              {/* </div> */}
              <h5 style={{ marginLeft: "15px" }}>Social Links</h5>
              <ul className="list-group list-group-flush mt-3">
                <li className="list-group-item d-flex align-items-center flex-wrap">
                  <img
                    src={linkedin}
                    style={{ width: "7%", marginRight: "5px" }}
                  />
                  {/* <h6 className="mb-0">LinkedIn</h6> */}
                  {/* <a href={`//${profileData?.social?.social_linkedin}`} className="link-text-social">
                  {profileData?.social?.social_linkedin}

                  </a> */}

                  <a href={`${profileData?.social?.social_linkedin}`} target="_blank" >
                    {profileData?.social?.social_linkedin}
                  </a>
                </li>

                <div className="row mx-auto">
                  {
                    <div className="col-sm-12 mt-4 btn btn-info " onClick={() => navigate('/admin/profile/edit')}>
                      Edit
                    </div>
                  }
                </div>
                {/* <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 className="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-twitter mr-2 icon-inline text-info"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>Twitter</h6>
                  <span className="text-secondary">@carivatechnologies</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 className="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-instagram mr-2 icon-inline text-danger"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>Instagram</h6>
                  <span className="text-secondary">carivatechnologies</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 className="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-facebook mr-2 icon-inline text-primary"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>Facebook</h6>
                  <span className="text-secondary">carivatechnologies</span>
                </li> */}
              </ul>

            </div>

          </div>
          <div className="col-md-8">
            <div className="mb-3 mt-5">
              <div className="profile-card-body">
                <h4>Personal Details</h4>
                <div className="row mt-3">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Full Name</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {profileData?.personalInformation?.fullName}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">DOB</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {new Date(
                      profileData?.personalInformation?.dateOfBirth
                    ).toLocaleDateString()}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Gender</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {profileData?.personalInformation?.gender?.name}
                  </div>
                </div>
                <hr />

                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Phone</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {profileData?.userId?.mobile ?
                      profileData?.userId?.mobile
                      :
                      `+91-No data`
                    }
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">E-mail</h6>
                  </div>

                  <div className="col-sm-9 link-text-social">
                    {profileData?.userId?.email}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Address</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {profileData?.currentLocation?.currentLocation_address}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">City</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {profileData?.currentLocation?.currentLocation_city?.name}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">District</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {profileData?.currentLocation?.currentLocation_district?.name}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">State</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {profileData?.currentLocation?.currentLocation_state?.name}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Country</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {profileData?.currentLocation?.currentLocation_country?.name}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Pincode</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {profileData?.currentLocation?.currentLocation_pinCode}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="profile-details-section col-sm-6 mb-3 d-flex">
              <div className="col-sm-12 mb-3">
                <div className="col-md-12">
              <div className="profile-card mb-3">
              <div className="profile-card-body">
              <h5>Professional Experience</h5>
                <div className="row mt-5">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">Current Designation Role</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    React Developer
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">Current Organization Name</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    Cariva Technologies
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">Total Experience In year</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    3
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">Skills</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    React, HTML, DevOps
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">No. of Projects Completed</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    4
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">Current Payout in month</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    40000
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">Expected Payout in month</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    60000
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
            </div>
          <div className="col-md-12">
            <div className="profile-card mb-3">
              <div className="profile-card-body">
                <h5>Education Details</h5>
                <div className="row mt-5">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">University/College Name</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    Asansol Engineering College
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">Qualification</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    B.Tech
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">Specialization</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    Computer Science Engineering
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">Year of Passing</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    2025
                  </div>
                </div>
                <hr />
              </div>
            </div>
            </div>
                {/* <div className="profile-card h-100">
                  <div className="profile-card-body">
                    <h6 className="d-flex align-items-center mb-3">Tech Status</h6>
                    <small>HTML5-80%</small>
                    <div className="progress mb-3" style={{ height: '5px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: '80%' }} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>DevOps-72%</small>
                    <div className="progress mb-3" style={{ height: '5px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: "72%" }} aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>.Net-89%</small>
                    <div className="progress mb-3" style={{ height: '5px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: '89%' }} aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>Android-55%</small>
                    <div className="progress mb-3" style={{ height: "5px" }}>
                      <div className="progress-bar" role="progressbar" style={{ width: "55%" }} aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>IOS-66%</small>
                    <div className="progress mb-3" style={{ height: '5px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: '66%' }} aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div> 
              </div> 
              {/* <div className="col-sm-6 mb-3">
                <div className="profile-card h-100">
                  <div className="profile-card-body">
                    <h6 className="d-flex align-items-center mb-3">Project Status</h6>
                    <small>Web Design</small>
                    <div className="progress mb-3" style={{ height: '5px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: '80%' }} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>Website Markup</small>
                    <div className="progress mb-3" style={{ height: '5px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: '72%' }} aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>One Page</small>
                    <div className="progress mb-3" style={{ height: '5px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: '89%' }} aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>Mobile Template</small>
                    <div className="progress mb-3" style={{ height: '5px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: '55%' }} aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>Backend API</small>
                    <div className="progress mb-3" style={{ height: '5px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: '66%' }} aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}



export default ViewProfileAdmin