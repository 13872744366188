// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register {
	background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
	background-size: cover;
	height: 747px;
	width: full-width;
}

.reset-pswd {
	background: #fff;
    border-radius: 15px;
	margin-top: 9%;
	margin-bottom: 8%;
	height: auto;
    width: 400px;
    justify-content: center;
    align-items: center;
    padding-left: 48px;
    padding-right: 47px;
    margin-left: 35px;
}

.main-title{
    font-size: 20px;
    text-align: center;
    margin-top: 35px;
}
.pswd-hd{
    font-size: 14px;
    margin-top: 35px;
}
.pswd-fld{
    padding: 1px;
}
.btn-reset{
    background-color: blue;
    border: 0;
    border-radius: 5px;
    padding: 7px 35px;
    margin-top: 45px;
    width: 99%;
    color: #fff;
    font-size: 16px;
}
.require-text{
    font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/pages/common/resetPassword/ResetPassword.css"],"names":[],"mappings":"AAAA;CACC,mDAA8C;CAC9C,sBAAsB;CACtB,aAAa;CACb,iBAAiB;AAClB;;AAEA;CACC,gBAAgB;IACb,mBAAmB;CACtB,cAAc;CACd,iBAAiB;CACjB,YAAY;IACT,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,YAAY;AAChB;AACA;IACI,sBAAsB;IACtB,SAAS;IACT,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,UAAU;IACV,WAAW;IACX,eAAe;AACnB;AACA;IACI,eAAe;AACnB","sourcesContent":[".register {\n\tbackground: url(../../../assets/images/bg.jpg);\n\tbackground-size: cover;\n\theight: 747px;\n\twidth: full-width;\n}\n\n.reset-pswd {\n\tbackground: #fff;\n    border-radius: 15px;\n\tmargin-top: 9%;\n\tmargin-bottom: 8%;\n\theight: auto;\n    width: 400px;\n    justify-content: center;\n    align-items: center;\n    padding-left: 48px;\n    padding-right: 47px;\n    margin-left: 35px;\n}\n\n.main-title{\n    font-size: 20px;\n    text-align: center;\n    margin-top: 35px;\n}\n.pswd-hd{\n    font-size: 14px;\n    margin-top: 35px;\n}\n.pswd-fld{\n    padding: 1px;\n}\n.btn-reset{\n    background-color: blue;\n    border: 0;\n    border-radius: 5px;\n    padding: 7px 35px;\n    margin-top: 45px;\n    width: 99%;\n    color: #fff;\n    font-size: 16px;\n}\n.require-text{\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
