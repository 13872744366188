// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JobDetails_container__dfTKg {
    padding: 20px;
    
}

.JobDetails_container__dfTKg h2 {
    font-size: 26px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/globalModal/components/Jobdetails/JobDetails.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;AAEjB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".container {\n    padding: 20px;\n    \n}\n\n.container h2 {\n    font-size: 26px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `JobDetails_container__dfTKg`
};
export default ___CSS_LOADER_EXPORT___;
