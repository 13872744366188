import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import LandingNavbar from "../../../pages/common/landingPage/components/landingNavbar/LandingNavbar";
import LandingFooter from "../../../pages/common/landingPage/components/landingFooter/LandingFooter";

const Landing = () => {
  const authentication = useSelector((state) => state?.authentication);
  const { pathname } = useLocation();

  console.log("Landing runs");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <>
      <LandingNavbar />
      <Outlet />
      <LandingFooter />
    </>
  );
  
  return <div>Landing</div>;
};

export default Landing;
