import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { globalModal } from '../../../Redux/actions/globalModal'
import { Outlet, Navigate, useNavigate } from 'react-router-dom'
import Navigationbar from '../navbar/Navigationbar'
import styles from "./Protected.module.css"
const Protected = (props) => {
  const authentication = useSelector((state) => state?.authentication)
  const { allowedUserRole } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const myProfile = useSelector((state) => state?.myProfile)
  useEffect(() => {
    if (authentication?.token) {
      if (!authentication?.emailValidate) {
        console.log('hi one false 16')
          // dispatch(globalModal({ component: 'EmailVerificationModal', size: 'md' }))
      } else if (!authentication?.isCreateProfile) {
        console.log('hi one false 19')

        navigate(`profile/create`)
        if (!authentication?.mobileValidate) {
          dispatch(globalModal({ component: 'VerifyMobile' }))
        }
      } else if (authentication?.isCreateProfile && authentication?.userRole === 'agency' && !myProfile?.profileData?.verifiedByAdmin?.status) {
        console.log('hi one false 26')

        navigate(`profile/view`)
      }

    }
  }, [authentication, window.location.pathname])
  

  return (<>
    {/* {
          !isSignupSignin &&
          <Navigationbar />
        } */}
    <Navigationbar />
    {
      authentication?.userRole === 'agency' && authentication?.isCreateProfile && myProfile?.profileData && !myProfile?.profileData?.verifiedByAdmin?.status &&
      <div className={styles.Verifivationmsg}>
        <p>Pending verification</p>
      </div>
    }

    {authentication.token ? (
      allowedUserRole.includes(authentication?.userRole) ?

        <Outlet />
        :
        <Navigate to={'/unauthorized'} />
    )
      :
      <Navigate to={'/signin'} />

    }


  </>
  )
}

export default Protected