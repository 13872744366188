import React from 'react'
import styles from "./Hirejava.module.css";
import HiringMethod from "../../landingPage/components/CTMethod/HiringMethod.jsx";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import badges from '../../../../assets/images/image.png';
import { FaRegCalendar } from 'react-icons/fa';
import bsnimg from "../../../../assets/images/male-candidate-interviewed-by-diverse-hr-team_1163-4676.avif";
import devimg from "../../../../assets/images/businesswoman-employee-shaking-hands_1098-15388.avif";
import { Link } from "react-router-dom";
import bigcompa from "../../../../assets/images/bbig-com.png";
import scroll from "../../../../assets/images/briefcase1.png";
import "../../landingPage/components/Carousel/DevCarousel/DevCarousels.jsx";
import Accordion from "react-bootstrap/Accordion";
import Clientele from '../../landingPage/components/Clientele.jsx';

function HireMongoDB() {
  return (
    <div>
            <div className={styles.landingPage_inner}>
            <div className="row">
          <div className={`${styles.banner_txt} col-7`} style={{marginTop:'13%'}}>
            <h1>Hire MongoDB Developers</h1>
            <p>
              Hire our expert Python Developers to build scalable web
              applications, data-driven solutions, and advanced machine learning
              models tailored to your business needs.
            </p>
            <ul className={styles.txt_points}>
              <li>
                <FaRegCalendar
                  style={{ color: "black", marginRight: "15px" }}
                ></FaRegCalendar>
                Multi-Lingual Support
              </li>
              <li>
                <FaRegCalendar
                  style={{ color: "black", marginRight: "15px" }}
                ></FaRegCalendar>
                Multi-Currency Support
              </li>
              <li>
                <FaRegCalendar
                  style={{ color: "black", marginRight: "15px" }}
                ></FaRegCalendar>
                Installation
              </li>
              <li>
                <FaRegCalendar
                  style={{ color: "black", marginRight: "15px" }}
                ></FaRegCalendar>
                Responsive Design
              </li>
              <li>
                <FaRegCalendar
                  style={{ color: "black", marginRight: "15px" }}
                ></FaRegCalendar>
                Multi-Vendor Marketplace
              </li>
            </ul>
            <Clientele/>
            {/* <div className="badge mt-4">
              <img src={badges} alt="Md Mudassar" />
            </div> */}
          </div>
          <div className="col-5">
            <form id="Banner_Form" style={{position: "absolute",
                backgroundColor: "#fff",
                top: "10%",
                padding: "40px",
                marginRight: "3%",
                marginLeft:'1.5%',
                color: "#000",
                fontWeight: "700",
                border: '1px solid rgb(29 7 70 / 6%)',
                boxShadow: 'rgb(35 0 97 / 32%) 0px 2px 20px',}}>
              <h2 className="text-center">Request for Callback</h2>
              <FloatingLabel controlId="floatingPassword" label="Name">
                <Form.Control
                  className={styles.form_control}
                  type="text"
                  placeholder="Name"
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="Email address">
                <Form.Control
                  className={styles.form_control}
                  type="email"
                  placeholder="name@example.com"
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingPassword" label="Phone Number">
                <Form.Control
                  className={styles.form_control}
                  type="Phone Number"
                  placeholder="Phone Number"
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingPassword" label="Country">
                <Form.Control
                  className={styles.form_control}
                  type="text"
                  placeholder="Country"
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingTextarea2" label="Comments">
                <Form.Control
                  className={styles.form_control}
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: "80px" }}
                />
              </FloatingLabel>
              <button className={`${styles.submit_btn} mt-3`}>Submit</button>
            </form>
          </div>
        </div>
            </div>
            <div
                className={styles.business}
                style={{ backgroundColor: "rgb(245 245 245)" }}
            >
                <div className="row">
                    <div className="col-6">
                        <img
                            src={bsnimg}
                            alt="Md Mudassar"
                            style={{ width: "95%", padding: "60px", borderRadius: "100px" }}
                        />
                    </div>
                    <div className={`col-6 p-5 ${styles.about_details}`}>
                        <h5>High Quality Cost Ratio</h5>
                        <h2>
                            Hire pre-vetted developers at half the costs from Talnfinium
                        </h2>
                        <p>
                            Quickly and efficiently build your engineering team with our
                            deeply vetted talent. We have more than 150+ tech stacks
                            developers with us like React, Node, Python, Angular, Swift, React
                            Native, Android, Java, Rails, Golang, DevOps, ML, Data Engineers,
                            and more.
                        </p>

                        {/* <button
              className={styles.hire_buttons}
              onClick={() =>
                dispatch(
                  globalModal({
                    component: "ContactForm",
                    closeButton: true,
                    size: "lg",
                  })
                )
              }
            >
              Hire Talents
            </button> */}
                    </div>
                </div>
            </div>
            <div className={styles.business}>
                <div className="row">
                    <div className={`col-6 p-5 ${styles.about_details}`}>
                        <h5>Effective Collaboration</h5>
                        <h2>Easy to Manage Administrative Reports </h2>
                        <p>
                            Talnfinium's Workspace gives you even more visibility into your
                            remote developer’s work with automatic time tracking & virtual
                            daily stand-ups. High visibility makes our developers easy to
                            engage and ensures their work aligns with what’s valuable to you.
                        </p>
                        {/* <button className={styles.hire_buttons}>Apply for Jobs</button> */}
                        <Link className={styles.hire_buttons} to="/apply-job">
                            Find Jobs
                        </Link>
                    </div>
                    <div className="col-6">
                        <img
                            src={devimg}
                            alt="Md Mudassar"
                            style={{ width: "95%", padding: "60px", borderRadius: "100px" }}
                        />
                    </div>
                </div>
            </div>

            <HiringMethod />

            {/* <CarivaTechnologiesResources /> */}

            <div className={styles.tech_stacks}>

            </div>

            <div className={styles.tech_stacks2}>
                <h6>Based On Roles</h6>
            </div>
            <div
                className={styles.TechStackSearchResult}
                style={{ margin: "2% 10%" }}
            >
                <div className={styles.techstacks}>
                    <img src={scroll} style={{ width: "15%" }} alt="Md Mudassar" />
                    <h5>Full Stack Dev...</h5>
                </div>
                <div className={styles.techstacks}>
                    <img src={scroll} style={{ width: "15%" }} alt="Md Mudassar" />
                    <h5>Software Dev...</h5>
                </div>
                <div className={styles.techstacks}>
                    <img src={scroll} style={{ width: "15%" }} alt="Md Mudassar" />
                    <h5>Mobile App Dev...</h5>
                </div>
                <div className={styles.techstacks}>
                    <img src={scroll} style={{ width: "15%" }} alt="Md Mudassar" />
                    <h5>UI/UX Design...</h5>
                </div>
                <div className={styles.techstacks}>
                    <img src={scroll} style={{ width: "15%" }} alt="Md Mudassar" />
                    <h5>Front-end Dev...</h5>
                </div>
                <div className={styles.techstacks}>
                    <img src={scroll} style={{ width: "15%" }} alt="Md Mudassar" />
                    <h5>Back-end Dev...</h5>
                </div>
                <div className={styles.techstacks}>
                    <img src={scroll} style={{ width: "15%" }} alt="Md Mudassar" />
                    <h5>Wordpress Dev...</h5>
                </div>
                <div className={styles.techstacks}>
                    <img src={scroll} style={{ width: "15%" }} alt="Md Mudassar" />
                    <h5>Cloud Engin...</h5>
                </div>
                <div className={styles.techstacks}>
                    <img src={scroll} style={{ width: "15%" }} alt="Md Mudassar" />
                    <h5>IOS Dev...</h5>
                </div>
                <div className={styles.techstacks}>
                    <img src={scroll} style={{ width: "15%" }} alt="Md Mudassar" />
                    <h5>Database Man...</h5>
                </div>

                {/* <div className={styles.tech_stacks3}>
          <h6 className={styles.cool_link}>See All Roles</h6>
          </div> */}
            </div>
            <div className="hire_btn2 text-center">
                {/* <button
          className={styles.hire_btn2}
          onClick={() => {
            dispatch(
              globalModal({
                component: "ContactForm",
                closeButton: false,
                size: "lg",
              })
            );
          }}
        >
          Hire Now
        </button> */}

            </div>
            <div className="choose_us">
                <div className="feat bg-gray pt-3 pb-4">
                    <div className={styles.container}>
                        <div className="row">
                            <div className={styles.tech_stacks}>
                                <h1>Let's Grow - Together</h1>
                            </div>

                            <div className="col-lg-4 col-sm-6">
                                <div className={styles.item}>
                                    <h6>Boost your revenue</h6>
                                    <p>
                                        We use the latest technology for the latest world because we
                                        know the demand of people around the world.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className={styles.item}>
                                    <h6>Talent Bench</h6>
                                    <p>
                                        You can streamline and improve your hiring process with the
                                        help of our Talent Bench.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className={styles.item}>
                                    <h6>Expand your network</h6>
                                    <p>
                                        Don't struggle to discover the ideal developer for your team
                                        with the help of Talnfinium.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6">
                                <div className={styles.item}>
                                    <h6>Win more clients</h6>
                                    <p>
                                        Get business referrals, co-marketing, sales enablement,
                                        exclusive events, and more.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className={styles.item}>
                                    <h6>Scale your agency</h6>
                                    <p>
                                        We are always Access agency-specific tools, certification
                                        for employees, and dedicated support.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className={styles.item}>
                                    <h6>700+</h6>
                                    <p>
                                        If our customer has any problem and we have Active
                                        agency-managed brands.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.bgbnnr}>
                <div>
                    <h3>Top Companies Hiring Now</h3>
                    <h4>
                        Join top companies and fast-scaling startups who have trusted
                        Talnfinium
                    </h4>
                    <img src={bigcompa} alt="Md Mudassar" />
                </div>
                <div className="hire_btn3 text-center mt-4 mb-1">
                    <a href="/apply-job">
                        <button className={styles.hire_btn3}>Find Dream Jobs</button>
                    </a>
                </div>
            </div>

            
            <div className="hire_btn2 text-center mt-3 mb-3">
                {/* <button
          className={styles.hire_btn2}
          onClick={() => {
            dispatch(
              globalModal({
                component: "ContactForm",
                closeButton: false,
                size: "lg",
              })
            );
          }}
        >
          Hire Talent Now
        </button> */}
            </div>

            <div className={styles.accordionsec}>
                <h1 style={{ fontSize: "35px", marginBottom: "40px" }}>
                    Frequently Asked Questions
                </h1>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className={styles.headrr}>
                            Why should I choose Talnfinium?
                        </Accordion.Header>
                        <Accordion.Body>
                            Talnfinium takes away the need for you to look for work on your
                            own, find clients, or spend time crafting a professional résumé.
                            We take care of the preparatory work to match you with qualified
                            leads and streamline the onboarding procedure.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            How do I create a profile on Talnfinium?
                        </Accordion.Header>
                        <Accordion.Body>
                            There are a few easy steps to creating your Talnfinium profile:
                            register, submit your résumé, fill out the work experience
                            questionnaire, and take the technical skill test.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            What are technical rounds, and is it possible to retake them?
                        </Accordion.Header>
                        <Accordion.Body>
                            Technical tests on Talnfinium are evaluations of your language
                            proficiency and tech stack proficiency. The longest these tests
                            can take is forty-five minutes. You have 30 days to repeat the
                            test if you don't pass the first time.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>
                            What qualities does Talnfinium find in developers?
                        </Accordion.Header>
                        <Accordion.Body>
                            Talnfinium seeks developers with a track record of meeting project
                            deadlines, proactive behavior, the capacity to deliver
                            high-quality code without requiring major modifications, and
                            dependable and clean code standards.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>
                            How does Talnfinium different from other platforms?
                        </Accordion.Header>
                        <Accordion.Body>
                            Unlike many competitors who mostly offer freelance services,
                            Talnfinium sets itself apart by providing remote engineers with
                            full-time, long-term projects. Our distinctive company strategy
                            and core principles make us stand out.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>
                            Are there any fees for developers to use Talnfinium?
                        </Accordion.Header>
                        <Accordion.Body>
                            No, Talnfinium does not require developers to pay any fees. For
                            those who join up, software developers can use the service for
                            free.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            {/* <div className={styles.client_sec}>
        <h1>Our Clientele</h1>
        <Clientele />

      </div> */}
        </div>
  )
}

export default HireMongoDB