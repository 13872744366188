import React, { useEffect } from 'react'
import styles from "../../forAdmin/candidates/Candidates.module.css"
import { useDispatch, useSelector } from 'react-redux'
import { appliedJobAction } from '../../../Redux/actions/job/appliedJobAction'
import UnitJobView from '../../../components/common/unitJobView/UnitJobView'

const AppliedJob = () => {
    const dispatch = useDispatch()
    const userId = useSelector((state) => state?.authentication?.userId)
    const { documents, appliedJobs_idArray, shortlistedJobs_idArray } = useSelector((state) => state?.appliedJob)
    console.log('documents', documents)
    useEffect(() => {
        dispatch(appliedJobAction({ _id: userId }))
    }, [])
    return (
        <div className='container-fluid p-0 mt-0 d-flex flex-column align-items-center'>
            <section className={styles.banner}>
                {/* <h3 style={{ textTransform: 'capitalize' }}>mhgfrr</h3> */}
                <h4>
                    <span>Applied : {appliedJobs_idArray?.length}</span>
                    <span>Shortlisted : {shortlistedJobs_idArray?.length}</span>
                </h4>
                {/* <div className={styles.searchbar}>
            <input type="text" placeholder="Enter name or member id" name='searchInput' value={searchInput} onChange={inputOnChangeHandle} />

            <button>Search</button>

          </div> */}
            </section>

            {
                documents?.length > 0 ?
                    documents?.map((item) =>

                        <UnitJobView key={item?._id} data={item} />
                    )
                    :
                    "No applied job"
            }
        </div>
    )
}

export default AppliedJob