import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./BenchResources.css";
import BenchresourcesTable from "./BenchresourcesTable";
import Accordion from "react-bootstrap/Accordion";
import Carilogo from "../../../assets/images/cari-logo.png";
import { useLocation } from "react-router-dom";

const BenchResources = () => {
  const { pathname } = useLocation();

  console.log("Landing runs");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  let count = 0;

  // const data = [
  //     {
  //         name: ["HTML", "CSS", "Javascript"],
  //         tableData: [
  //             {
  //                 resourceName: "Aamir Shabbir",
  //                 experience: 4,
  //                 techStack: [".net", "Python", "Java"],
  //                 screeningTestMarks: 7.5,
  //                 technicalAssessment: 7,
  //                 resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
  //             },
  //             {
  //                 resourceName: "Md Mudassar",
  //                 experience: 4,
  //                 techStack: [".net", "Python", "Java"],
  //                 screeningTestMarks: 7.5,
  //                 technicalAssessment: 7,
  //                 resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
  //             },
  //             {
  //                 resourceName: "Nigar Fatima",
  //                 experience: 4,
  //                 techStack: [".net", "Python", "Java"],
  //                 screeningTestMarks: 7.5,
  //                 technicalAssessment: 7,
  //                 resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
  //             },
  //             {
  //                 resourceName: "Amina Khatoon",
  //                 experience: 4,
  //                 techStack: [".net", "Python", "Java"],
  //                 screeningTestMarks: 7.5,
  //                 technicalAssessment: 7,
  //                 resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
  //             },
  //         ]
  //     },
  //     {
  //         name: ["Java"],
  //         tableData: [
  //             {
  //                 resourceName: "Aamir Shabbir",
  //                 experience: 4,
  //                 techStack: [".net", "Python", "Java"],
  //                 screeningTestMarks: 7.5,
  //                 technicalAssessment: 7,
  //                 resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
  //             },
  //             {
  //                 resourceName: "Saad Ahmed",
  //                 experience: 4,
  //                 techStack: [".net", "Python", "Java"],
  //                 screeningTestMarks: 7.5,
  //                 technicalAssessment: 7,
  //                 resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
  //             },
  //         ]
  //     },
  //     {
  //         name: ["MEAN", "MERN"],
  //         tableData: [
  //             {
  //                 resourceName: "Aamir Shabbir",
  //                 experience: 4,
  //                 techStack: [".net", "Python", "Java"],
  //                 screeningTestMarks: 7.5,
  //                 technicalAssessment: 7,
  //                 resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
  //             },
  //             {
  //                 resourceName: "Saad Ahmed",
  //                 experience: 4,
  //                 techStack: [".net", "Python", "Java"],
  //                 screeningTestMarks: 7.5,
  //                 technicalAssessment: 7,
  //                 resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
  //             },
  //             {
  //                 resourceName: "Saad Ahmed",
  //                 experience: 4,
  //                 techStack: [".net", "Python", "Java"],
  //                 screeningTestMarks: 7.5,
  //                 technicalAssessment: 7,
  //                 resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
  //             },
  //             {
  //                 resourceName: "Saad Ahmed",
  //                 experience: 4,
  //                 techStack: [".net", "Python", "Java"],
  //                 screeningTestMarks: 7.5,
  //                 technicalAssessment: 7,
  //                 resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
  //             },
  //         ]
  //     }
  // ]
  return (
    <div className="BenchResources">
      <section className="banner">
        <div className="row">
          <h2>Talent Bench</h2>
        </div>
      </section>
    </div>
  );
};

export default BenchResources;
