import React from 'react'
import styles from "../Confirmation/CreateProfileSubmission.module.css"
import { useDispatch } from "react-redux"
const SuccessfullSignup = () => {

  const dispatch = useDispatch()

  return (
    <div className={`${styles.SubmissionModal} ${styles.SpecialWidthModal}`} >
      <h3>Confirmation</h3>
      <p style={{ width: "fit-content" }} >
        You have successfully logged into the signup page as developer / agency / admin <br />
        <b>
          Please check your email for login credentials.
        </b>

      </p>
      <div className={styles.SubmissionModalActions}>
        <button onClick={() => dispatch({ type: 'HIDE_MODAL' })} style={{ width: "30%" }} >Continue</button>

      </div>
    </div>
  )
}

export default SuccessfullSignup
