import React from "react";
import styles from "../../LandingPage.module.css";
import indstry1 from '../../../../../../src/assets/images/ecommerce (1).png'
import trans from '../../../../../assets/images/shipping.png'
import educa from '../../../../../assets/images/education.png'
import finance from '../../../../../assets/images/earning.png'
import labor from '../../../../../assets/images/industrial.png'
import aid from '../../../../../assets/images/protection.png'
import automo from '../../../../../assets/images/configuration.png'
import bank from '../../../../../assets/images/money-transfer.png'
import foods from '../../../../../assets/images/fast-food.png'
import travel from '../../../../../assets/images/destination.png'
import software from '../../../../../assets/images/software-development.png'
import construc from '../../../../../assets/images/construction.png'


const industries = () => {
  return (
    <div>

      <div className="text-center mt-5 mb-3">
      <h1>Industries We Serve</h1>
      </div>
      
      <div
        className={`${styles.LandingPageTopDevelopers} justify-content-center mx-auto col-10`}
      >
        

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={indstry1} alt="Md Mudassar" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>eCommerce </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={trans} alt="Md Mudassar" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Transport </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={educa} alt="Md Mudassar" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Education </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={finance} alt="Md Mudassar" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Finance </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={labor} alt="Md Mudassar" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Industrial </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={aid} alt="Md Mudassar" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Healthcare </h5>
                </div>
            </div>
          </div>
        </div>





      </div>

      <div
        className={`${styles.LandingPageTopDevelopers} justify-content-center mx-auto col-10`}
      >
        

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={automo } alt="Md Mudassar" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Automobile </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={foods} alt="Md Mudassar" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Food </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={software} alt="Md Mudassar" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Software </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={travel } alt="Md Mudassar" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Travel </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={construc } alt="Md Mudassar" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Construction </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={bank } alt="Md Mudassar" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Banking </h5>
                </div>
            </div>
          </div>
        </div>





      </div>






    </div>
  );
};

export default industries;
