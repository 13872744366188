// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown_background{
    background-image: linear-gradient(to right, #18042B , #0b1bff);
font-size: 18px;
padding: 6px 70px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/childB/commonDropdown/CustomDropdown.css"],"names":[],"mappings":"AAAA;IACI,8DAA8D;AAClE,eAAe;AACf,iBAAiB;AACjB","sourcesContent":[".dropdown_background{\n    background-image: linear-gradient(to right, #18042B , #0b1bff);\nfont-size: 18px;\npadding: 6px 70px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
