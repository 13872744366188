// import axios from "axios"

// const setToken = (token) => {
//     // const token ='merajrizwan1234567890'
//     if (token) {
//         axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
//     } else {
//         delete axios.defaults.headers.common['Authorization']
//     }
// }


// // axios request with token
// const AxiosInstance=axios.create()
// AxiosInstance.interceptors.request.use(
//     (config) => {
//         // setToken('merajrizwan1234567890')

//         // console.log(config)
//         return config
//     },
//     (error) => {
//         return Promise.reject(error)
//     }
// )


// // axios response with token
// axios.interceptors.response.use(
//     (response) => {
//         return response
//     },
//     (error) => {
//         return Promise.reject(error)
//     }
// )




// // axios request without token

// const axiosWithoutToken = axios.create()

// axiosWithoutToken.interceptors.request.use(
//     (config) => {
//         return config
//     },
//     (error) => {
//         Promise.reject(error)
//     }
// )


// // axios response without token

// axiosWithoutToken.interceptors.response.use(
//     (response) => {
//         return response
//     },
//     (error) => {
//         Promise.reject(error)
//     }
// )

// // export { axios, axiosWithoutToken, setToken }
// export default AxiosInstance



// axiosInstance.js

import axios from 'axios';
import { store } from '../../Redux/store'
import { logoutAction } from '../../Redux/actions/logoutAction';
export const axiosInstanceWithToken = axios.create();

// axios requrest with token
axiosInstanceWithToken.interceptors.request.use(config => {
  const { authentication } = store.getState()
  // console.log('authentication',authentication)
  store.dispatch({ type: 'AXIOS_PROCESSING' })
  if (authentication.token) {
    config.headers.Authorization = `Bearer ${authentication.token}`;
  }

  return config;
});


// axios response with token
axiosInstanceWithToken.interceptors.response.use(config => {
  const { authentication } = store.getState()
  // console.log('authentication91111',config)
  if (authentication.token) {
    config.headers.Authorization = `Bearer ${authentication.token}`;
  }
  if (config?.data?.errorCode === 401) {
    store.dispatch(logoutAction())
  }
  // console.log('config my config', config)
  setTimeout(() => {
    store.dispatch({ type: 'AXIOS_SUCCESS' })

  }, 500);

  return config;
}, (err) => {
  if (err) {
    console.log('error in axios check in interceptor', err)
    store.dispatch({ type: 'AXIOS_FAILURE' })


  }
});





export const axiosInstanceWithoutToken = axios.create();

// axios requrest with token
axiosInstanceWithoutToken.interceptors.request.use(config => {
  // console.log('config my config 125', config)

  return config;
});


// axios response with token
axiosInstanceWithoutToken.interceptors.response.use(config => {
  // console.log('config my config 132', config)

  return config;
});












// module.exports= {axiosInstanceWithToken,axiosInstanceWithoutToken}
