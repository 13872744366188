import React, { useEffect } from 'react'
import styles from "./CreateProfileAdmin.module.css"
import { IoIosAddCircleOutline } from "react-icons/io";
import ProfilePageInputfeild from '../../../forDeveloper/profilePage/createProfile/ProfilePageInputfeild';
import LocationDropdown from '../../../forDeveloper/profilePage/createProfile/LocationDropdown';
import { useDispatch, useSelector } from "react-redux"
import { useFormik } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { initialValueAdmin, nameValueAdmin } from '../../../../assets/initialValues/createProfile'
import { locationAction } from '../../../../Redux/actions/locationAction';
import { fileUpload } from '../../../../Redux/actions/fileUpload/fileUpload';
import { BServer } from '../../../../server/backend';
import { adminProfileValidation } from '../../../../assets/yupValidation/createProfile/adminProfileValidation';
import { createProfileAdminAction } from '../../../../Redux/actions/profile/createProfileAdminAction';

const CreateProfileAdmin = () => {
  const authentication = useSelector((state) => state.authentication)
  const location = useSelector((state) => state?.location)
  const profileDetails = useSelector((state) => ['admin', 'sAdmin'].includes(authentication?.userRole) ? state?.myProfile?.profileData : {})
  const fileUploaded = useSelector((state) => state?.fileUpload)
  const userIdFromUseLocation = useLocation()

  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()
  console.log('profileDetails',profileDetails)
  const editOne = {
    fullName: profileDetails?.personalInformation?.fullName,
    dateOfBirth: profileDetails?.personalInformation?.dateOfBirth,
    gender: profileDetails?.personalInformation?.gender,
    profilePicture: profileDetails?.personalInformation?.profilePicture,
    email: profileDetails?.userId?.email,
    currentLocation_address: profileDetails?.currentLocation?.currentLocation_address,
    currentLocation_country: profileDetails?.currentLocation?.currentLocation_country,
    currentLocation_state: profileDetails?.currentLocation?.currentLocation_state,
    currentLocation_district: profileDetails?.currentLocation?.currentLocation_district,
    currentLocation_city: profileDetails?.currentLocation?.currentLocation_city,
    currentLocation_pinCode: profileDetails?.currentLocation?.currentLocation_pinCode,

    professionalExperience_currentDesignation: profileDetails?.professionalExperience?.professionalExperience_currentDesignation,

    social_linkedin: profileDetails?.social?.social_linkedin,
  }
  console.log('profile editing', profileDetails)
  const { values, handleChange, handleSubmit, resetForm, errors, setFieldValue, handleBlur, touched } = useFormik({
    initialValues: params?.edit ? editOne : { ...initialValueAdmin, email: authentication.userEmail }
    ,
    validationSchema: adminProfileValidation,
    enableReinitialize: true,

    // userIdFromUseLocation will only be provided with dispatch when agency try to edit there developer
    onSubmit: (values) => {
      dispatch(createProfileAdminAction(values, params?.edit === 'edit' ? 'EDIT' : 'CREATE', (prop) => navigate(prop), userIdFromUseLocation?.state?.userId))

    }
  })
  useEffect(() => {
    dispatch(locationAction('COUNTRY'))
  }, [values.currentLocation_country])
  useEffect(() => {
    if (values.currentLocation_country) {
      dispatch(locationAction('STATE', values.currentLocation_country?.geonameId))
    }
  }, [values.currentLocation_country])

  useEffect(() => {
    if (values.currentLocation_country) {
      dispatch(locationAction('DISTRICT', values.currentLocation_state?.geonameId))
    }
  }, [values.currentLocation_state])
  useEffect(() => {
    if (values.currentLocation_country) {
      dispatch(locationAction('CITY', values.currentLocation_district?.geonameId))
    }
  }, [values.currentLocation_district])
  console.log('values', values)
  console.log('errors', errors)

  return (
    <div className={styles.CreateProfileAdmin} >
      <div className={styles.ProfileAdmin_ProfilePhoto}>
        <img src={
          // `${BServer}/uploads/${fileUploaded?.profilePicture ? fileUploaded?.profilePicture?.file : values?.profilePicture}`
          values.profilePicture ?
            `${BServer}/uploads/${fileUploaded?.profilePicture ? fileUploaded?.profilePicture?.file : values?.profilePicture}`
            :
            `https://cdn-icons-png.flaticon.com/512/6596/6596121.png`
        } alt="ProfilePhoto" style={{ width: '7rem', height: '7rem' }} />
        <div className={styles.ProfilePagefileinput}>
          <label htmlFor="profilephoto">
            <IoIosAddCircleOutline size={"20px"} />
            Profile Photo <span style={{color:'red'}}>*</span></label>
            

            <span style={{color:'red',fontSize:'12px'}}>{touched.profilePicture && errors?.profilePicture}</span>
          <input type="file" accept="image/*" id="profilephoto" style={{ display: "none" }} name={nameValueAdmin?.profilePicture} onChange={async (e) => {
            dispatch(fileUpload(e, setFieldValue))

          }}  onBlur={handleBlur} />
        </div>
      </div>
      <div className={styles.ProfileAdmin_inputFeild}>
        <h3>Personal Details</h3>
        <div className={styles.DISPLAYFLEX}>
          <ProfilePageInputfeild label="Full Name" required={true} type="text" name={nameValueAdmin.fullName} value={values.fullName} onChange={handleChange} onBlur={handleBlur} error={touched.fullName && errors.fullName} />

          <ProfilePageInputfeild label="DOB" required={true} type="date" name={nameValueAdmin.dateOfBirth} 
          // value={values.dateOfBirth} 
          value={values?.dateOfBirth ? new Date(values?.dateOfBirth)?.toISOString()?.split('T')[0] : values?.dateOfBirth}
          onChange={handleChange} onBlur={handleBlur} error={touched.dateOfBirth && errors.dateOfBirth} />
        </div>
        {/* <div className={styles.DISPLAYFLEX}>
          <ProfilePageInputfeild label="Email Address" type="email" required={true} name={nameValueAdmin.fullName} value={values.fullName} onChange={handleChange} onBlur={handleBlur} error={touched.fullName && errors.fullName} />
          <ProfilePageInputfeild label="Phone No" type="tel" />
        </div> */}
        <div className={styles.DISPLAYFLEX}>
          <ProfilePageInputfeild label="Position" required={true} type="text" name={nameValueAdmin.professionalExperience_currentDesignation} value={values.professionalExperience_currentDesignation} onChange={handleChange} onBlur={handleBlur} error={touched.professionalExperience_currentDesignation && errors.professionalExperience_currentDesignation} />

          <ProfilePageInputfeild label="LinkedIn" type="url" required={true} name={nameValueAdmin.social_linkedin} value={values.social_linkedin} onChange={handleChange} onBlur={handleBlur} error={touched.social_linkedin && errors.social_linkedin} />
        </div>
        <div className={styles.DISPLAYFLEX}>
          <LocationDropdown label="Gender" values={[{ name: 'Male' }, { name: 'Female' }, { name: 'Other' }]} required={true} name={nameValueAdmin.gender} value={values.gender
          } setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.gender && errors.gender} previousValue={profileDetails?.personalInformation?.gender} />
          <ProfilePageInputfeild label="Email Address" type="email" required={true} name={nameValueAdmin.email} value={values.email} disabled={true} />

        </div>
        <div className={styles.DISPLAYFLEX}>
          <ProfilePageInputfeild label="Address" required={true} type="text" name={nameValueAdmin.currentLocation_address} value={values.currentLocation_address} onChange={handleChange} onBlur={handleBlur} error={touched.currentLocation_address && errors.currentLocation_address} />

          <LocationDropdown label="Current Location Country" values={location?.countries} required={true} name={nameValueAdmin.currentLocation_country} value={values.currentLocation_country} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.currentLocation_country && errors.currentLocation_country} previousValue={profileDetails?.currentLocation?.currentLocation_country} />

        </div>
        <div className={styles.DISPLAYFLEX}>
          <LocationDropdown label="Current Location State" values={location?.states} required={true} name={nameValueAdmin.currentLocation_state} value={values.currentLocation_state} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.currentLocation_state && errors.currentLocation_state} previousValue={profileDetails?.currentLocation?.currentLocation_state} />


          <LocationDropdown label="Current Location District" values={location?.districts} required={true} name={nameValueAdmin.currentLocation_district} value={values.currentLocation_district} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.currentLocation_district && errors.currentLocation_district} previousValue={profileDetails?.currentLocation?.currentLocation_district} />

        </div>


       

        <div className={styles.DISPLAYFLEX}>
          <LocationDropdown label="Current Location City" values={location?.cities} required={true} name={nameValueAdmin.currentLocation_city} value={values.currentLocation_city} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.currentLocation_city && errors.currentLocation_city} previousValue={profileDetails?.currentLocation?.currentLocation_city} />

          <ProfilePageInputfeild label="Current Location Pin code" required={true} type="text" name={nameValueAdmin.currentLocation_pinCode} value={values.currentLocation_pinCode} onChange={handleChange} onBlur={handleBlur} error={touched.currentLocation_pinCode && errors.currentLocation_pinCode} />



        </div>


        <div className={styles.DISPLAYFLEX}>

          <div className={styles.buttonwrapper}>
            <button type='submit' onClick={handleSubmit}>Save Profile</button>
            <button onClick={()=>resetForm()}>Reset</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateProfileAdmin