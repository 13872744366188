import * as Yup from 'yup'
import {nameValueDev} from '../../initialValues/createProfile'
export const devProfileValidation =
// Yup.object().shape(
    {

    [nameValueDev.profilePicture]:Yup.string().required('Profile picture is mandatory'),

    [nameValueDev.resume]:Yup.string().required(' Resume is mandatory'),

    [nameValueDev.fullName]:Yup.string().required(' Name is mandatory'),


    [nameValueDev.dateOfBirth]:Yup.string()
    .test('','Age should min 18 years',(value)=>isOver18(value))
    .required('Dob is mandatory'),

    [nameValueDev.gender]:Yup.object().required('Gender is mandatory'),

    [nameValueDev.email]: Yup
    .string()
    .email('email is invalid')
    .test('email is invalid', (value) => {
        return value.split('@')[1]?.includes('.')
    })
    .required('email is required'),

    [nameValueDev.currentLocation_country]:Yup.object().test((value)=>Object.keys(value)?.length).required('Country is mandatory'),

    [nameValueDev.currentLocation_state]:Yup.object().test((value)=>Object.keys(value)?.length).required('State is mandatory'),

    [nameValueDev.currentLocation_district]:Yup.object().test((value)=>Object.keys(value)?.length).required('District is mandatory'),

    [nameValueDev.currentLocation_city]:Yup.object().test((value)=>Object.keys(value)?.length).required('City is mandatory'),

    [nameValueDev.currentLocation_pinCode]:Yup.string().required('Pincode is mandatory'),

    [nameValueDev.preferredLocation]:Yup.array().test('must','Prefered location is invalid',(value=>value.length>0)).required('Prefered location is mandatory'),

    [nameValueDev.education_instituteName]:Yup.string().required('Institute is mandatory'),

    [nameValueDev.education_qualification]:Yup.object().required('Qualification is mandatory'),

    [nameValueDev.education_specializaion]:Yup.string().required('Specilization is mandatory'),

    [nameValueDev.education_yearOfPassing]:Yup.object().required('Year of passing is  mandatory'),

    // [nameValueDev.professionalExperience_currentDesignation]:Yup.string().required('designation is mandatory'),

    // [nameValueDev.professionalExperience_currentOrganization]:Yup.string().required('organization is mandatory'),

    [nameValueDev.professionalExperience_totalExperienceInYear]:Yup.number().test('','Must be positive',(value)=>value>=0).test('','Must be less than 30',(value)=>value<30),

    [nameValueDev.professionalExperience_totalExperienceInMonth]:Yup.number().test('','Must be positive',(value)=>value>=0).test('','Must be less than 12',(value)=>value<12),
    // [nameValueDev.professionalExperience_noticePeriodInMonth]:Yup.object().required('notice in month is  mandatory'),

    [nameValueDev.professionalExperience_skills]:Yup.array().test('Must','Select min 5 skills',(value=>value.length>=3)).test('must','Select max upto 15 skills',(value=>value.length<16)).required('Skills is mandatory'),

    // [nameValueDev.professionalExperience_projectCompleted]:Yup.number().required('project is  mandatory'),

    // [nameValueDev.professionalExperience_currentPayout]:Yup.number().required(' current payout is  mandatory'),

    [nameValueDev.professionalExperience_expectedPayout]:Yup.number().required(' Expected payout is  mandatory'),

    [nameValueDev.social_linkedin]:Yup.string().test('','Invalid url',(value)=>value.includes('linkedin.com')).required('Linkedin is mandatory'),

    // [nameValueDev.social_git]:Yup.string().required('git is mandatory'),

}
// )



export function isOver18(birthdate) {
    // Parse the birthdate string into a Date object
    var dob = new Date(birthdate);
    
    // Get the current date
    var now = new Date();
    
    // Check if the birthdate is in the future
    if (dob > now) {
        return false; // Birthdate is in the future, person cannot be over 18
    }
    
    // Calculate the age by the difference in years
    var age = now.getFullYear() - dob.getFullYear();
    
    // Adjust the age if the current date hasn't occurred yet this year
    if (now.getMonth() < dob.getMonth() || (now.getMonth() === dob.getMonth() && now.getDate() < dob.getDate())) {
        age--;
    }
    
    // Check if the age is over 18
    return age >= 18;
}