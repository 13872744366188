import React, { useEffect } from 'react'
import styles from "./CreateProfileAgency.module.css"
import AgencyPageInputfeild from '../../../forDeveloper/profilePage/createProfile/ProfilePageInputfeild'
import AgencyPageFileInputFeild from './AgencyPageFileInputFeild'
import AgencyPageDropdownFeild from '../../../forDeveloper/profilePage/createProfile/ProfilePageDropdownFeild'
import AgencyPageCascadingDropDown from "../../../forDeveloper/profilePage/createProfile/CascadingDropdown"
import { nameValueAgency, initialValueAgency } from '../../../../assets/initialValues/createProfile'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import LocationDropdown from '../../../forDeveloper/profilePage/createProfile/LocationDropdown'
import CascadingDropdown from '../../../forDeveloper/profilePage/createProfile/CascadingDropdown'
import { locationAction } from '../../../../Redux/actions/locationAction'
import { createProfileAgencyAction } from '../../../../Redux/actions/profile/createProfileAgencyAction'
import { useNavigate, useParams } from 'react-router-dom'
import { agencyProfileValidation } from '../../../../assets/yupValidation/createProfile/agencyProfileValidation'
import { viewProfileAgencyAction } from '../../../../Redux/actions/profile/viewProfile/viewProfileAgencyAction'
import { fileUpload } from '../../../../Redux/actions/fileUpload/fileUpload'
import { BServer } from '../../../../server/backend'
import ReactQuill from 'react-quill';
import AgencyPageAssignToDropDown from './AgencyPageAssignToDropDown'


const CreateProfileAgency = () => {
  const authentication = useSelector((state) => state.authentication)
  const dispatch = useDispatch()
  const location = useSelector((state) => state?.location)
  const navigate = useNavigate()
  const params = useParams()
  const profileDetails = useSelector((state) => authentication?.userRole == 'agency' ? state?.myProfile?.profileData : state?.viewProfileAgencyAction?.profileData)
  const fileUploaded = useSelector((state) => state?.fileUpload)
  const formDropdown = useSelector((state) => state?.formDropdown)
  console.log('formDropdown', formDropdown)
  console.log('fileUploaded', fileUploaded)
  const editOne = {
    [nameValueAgency.fullName]: profileDetails?.personalInformation?.fullName,
    [nameValueAgency.description]: profileDetails?.personalInformation?.description,
    [nameValueAgency.profilePicture]: profileDetails?.personalInformation?.profilePicture,
    [nameValueAgency.email]: profileDetails?.personalInformation?.email,

    [nameValueAgency.overview_areaOfExpertise]: profileDetails?.overview?.overview_areaOfExpertise,
    [nameValueAgency.overview_companySize]: profileDetails?.overview?.overview_companySize,
    [nameValueAgency.overview_industrialExperience]: profileDetails?.overview?.overview_industrialExperience,

    [nameValueAgency.location_address]: profileDetails?.location?.location_address,
    [nameValueAgency.location_country]: profileDetails?.location?.location_country,
    [nameValueAgency.location_state]: profileDetails?.location?.location_state,
    [nameValueAgency.location_district]: profileDetails?.location?.location_district,
    [nameValueAgency.location_city]: profileDetails?.location?.location_city,
    [nameValueAgency.location_pinCode]: profileDetails?.location?.location_pinCode,

    [nameValueAgency.social_website]: profileDetails?.social?.social_website,
    [nameValueAgency.social_linkedin]: profileDetails?.social?.social_linkedin,

    [nameValueAgency.documents_registration]: profileDetails?.documents?.documents_registration,
    [nameValueAgency.documents_panCard]: profileDetails?.documents?.documents_panCard,
    [nameValueAgency.documents_gstCertificate]: profileDetails?.documents?.documents_gstCertificate,
    [nameValueAgency.otherDocuments]: profileDetails?.documents?.otherDocuments,




  }
  const { values, handleChange, handleSubmit, resetForm, errors, setFieldValue, handleBlur, touched, setValues } = useFormik({
    initialValues: params?.edit ? editOne : { ...initialValueAgency, email: authentication.userEmail, },

    // { ...initialValueAgency, email: authentication.userEmail },
    validationSchema: agencyProfileValidation,
    onSubmit: (values) => {
      dispatch(createProfileAgencyAction(values, params?.edit === 'edit' ? 'EDIT' : 'CREATE', (props) => {
        console.log('navvvvv', props)
        navigate(props)
      }))

    }
  })

console.log('location location',location)
  const handleAddDocument = () => {
    setValues({
      ...values,
      otherDocuments: [...values?.otherDocuments, { name: '', value: '' }],
    });
  };

  const handleDocumentChange = (index, name, value) => {
    const updatedDocuments = [...values?.otherDocuments];
    // const updatedDocument = { ...updatedDocuments[index], [name]: value };
    updatedDocuments[index][name] = value
    // updatedDocuments[index] = updatedDocument;
    setValues({
      ...values,
      otherDocuments: updatedDocuments,
    });
  };

  useEffect(() => {
    dispatch(locationAction('COUNTRY'))
  }, [values.location_country])
  useEffect(() => {
    if (values.location_country) {
      dispatch(locationAction('STATE', values.location_country?.geonameId))
    }
  }, [values.location_country])

  useEffect(() => {
    if (values.location_country) {
      dispatch(locationAction('DISTRICT', values.location_state?.geonameId))
    }
  }, [values.location_state])
  useEffect(() => {
    if (values.location_country) {
      dispatch(locationAction('CITY', values.location_district?.geonameId))
    }
  }, [values.location_district])

  useEffect(() => {
    if (params?.edit === 'edit' && (authentication?.userRole === 'admin' || authentication?.userRole === 'sAdmin')) {
      dispatch(viewProfileAgencyAction())
    }
  }, [])
  console.log('valuesss', values)
  console.log('errorrsss', errors)

  const assignVerificationTo = [
    { name: "Nav Verma", email: "carivaverification1@carivatechnologies.com", _id: "65dc59087d110c17b53f5b26", memberId: "ADM276907282" },

    { name: "Zafar Hussian", email: "carivaverification2@carivatechnologies.com", _id: "65d32b8c791ca8c5b80a479b", memberId: "ADM864889706" },

    { name: "Naheed Zakia", email: "carivaverification1@carivatechnologies.com", _id: "65dc59087d110c17b53f5b26", memberId: "ADM276907282" },

    { name: "Meraj Rizwan", email: "carivaverification2@carivatechnologies.com", _id: "65d32b8c791ca8c5b80a479b", memberId: "ADM864889706" }
  ].sort(() => Math.random() - 0.5);

  return (
    <>
      <div className={styles.Agency_page}>
        <div className={styles.Agency_page_profile_section}>
          <img src={
            // `${BServer}/uploads/${fileUploaded?.profilePicture ? fileUploaded?.profilePicture?.file : values?.profilePicture}`
            values.profilePicture ?
              `${BServer}/uploads/${fileUploaded?.profilePicture ? fileUploaded?.profilePicture?.file : values?.profilePicture}`
              :
              `https://cdn-icons-png.flaticon.com/512/6596/6596121.png`

          } alt="ProfilePhoto" style={{ width: '7rem', height: '7rem' }} />
          <span className={styles.ProfilePageInputfield_error_msg}>{touched?.profilePicture && errors?.profilePicture}</span>
          <div className={styles.AgencyPagefileinput}>
            <label htmlFor="profilephoto">
              {/* <IoAddCircleOutline size={"20px"} />  */}
              Add logo <span style={{color:"red"}} >*</span> </label>

            <input type="file" accept="image/*" id="profilephoto" style={{ display: "none" }} name={nameValueAgency.profilePicture} onChange={async (e) => {
              dispatch(fileUpload(e, setFieldValue))

            }} />
          </div>

          {/* <h3>{values.fullName}</h3> */}

        </div>
        <div className={styles.Agency_page_personal_section}>

          <h3>Basic Information</h3>

          <AgencyPageInputfeild label="Company Name" required={true} type="text" name={nameValueAgency.fullName} value={values.fullName} onChange={handleChange} onBlur={handleBlur} error={touched?.fullName && errors?.fullName} disabled={params?.edit} />

          <AgencyPageInputfeild label="Company Email" required={true} type="email" disabled={true} value={values.email} onChange={handleChange} />


          <h3 style={{paddingTop:"30px"}} >Social Links</h3>
          <AgencyPageInputfeild label="Company Website" required={true} type="url" name={nameValueAgency.social_website} value={values.social_website} onChange={handleChange} onBlur={handleBlur} error={touched?.social_website && errors?.social_website} />

          <AgencyPageInputfeild label="Company LinkedIn" required={true} type="url" name={nameValueAgency.social_linkedin} value={values.social_linkedin} onChange={handleChange} onBlur={handleBlur} error={touched?.social_linkedin && errors?.social_linkedin} disabled={params?.edit} />

          <div className={styles.textareainput}>
            <label htmlFor="">Description <span className={styles.requiredSpan} >*</span></label>
            <textarea name={nameValueAgency.description} id="" placeholder="Enter about your company" rows={3} value={values.description} onChange={handleChange} onBlur={handleBlur}></textarea>
            <span className={styles.ProfilePageInputfield_error_msg}>{touched?.description && errors?.description}</span>

            {/* <ReactQuill
              theme="snow"
              name={nameValueAgency.description}
              value={values.description}
              onChange={handleChange}

            /> */}
          </div>

          <h3 style={{paddingTop:"30px"}} >Documents</h3>

          <AgencyPageFileInputFeild label="Registration Cretificate" accept="application/pdf" name={nameValueAgency.documents_registration} value={values.documents_registration} onChange={async (e) => {
            dispatch(fileUpload(e, setFieldValue))

          }} />
          <span className={styles.ProfilePageInputfield_error_msg}>{touched?.documents_registration && errors?.documents_registration}</span>

          <AgencyPageFileInputFeild label="Pan Card" accept="application/pdf" name={nameValueAgency.documents_panCard} value={values.documents_panCard} onChange={async (e) => {
            dispatch(fileUpload(e, setFieldValue))

          }} />
          <span className={styles.ProfilePageInputfield_error_msg}>{touched?.documents_panCard && errors?.documents_panCard}</span>

          <AgencyPageFileInputFeild label="GST Registration" accept="application/pdf" name={nameValueAgency.documents_gstCertificate} value={values.documents_gstCertificate} onChange={async (e) => {
            dispatch(fileUpload(e, setFieldValue))

          }} />
          <span className={styles.ProfilePageInputfield_error_msg}>{touched?.documents_gstCertificate && errors?.documents_gstCertificate}</span>

          <div className={styles.addDocumentFeild}>
            {values?.otherDocuments?.map((doc, index) => (
              <div key={index}>
                <input
                  type="text"
                  name={`otherDocuments[${index}].name`}
                  value={doc.name}
                  onChange={(e) => handleDocumentChange(index, 'name', e.target.value)}
                  placeholder='Enter Document Name'
                />
                <AgencyPageFileInputFeild
                  label={doc.name}
                  accept="application/pdf"
                  name={values?.otherDocuments[index]?.name}
                  value={fileUpload[values?.otherDocuments[index]?.name]}
                  onChange={async (e) => {
                    // Handle file upload logic, you can dispatch actions or perform actions here
                    dispatch(fileUpload(e, setFieldValue, setValues, values, index))

                    console.log('File upload logic here');
                  }}
                />
              </div>
            ))}
            <button type="button" onClick={handleAddDocument}>
              Add Document
            </button>
          </div>

        </div>
        <div className={styles.Agency_page_experience_section}>

          <h3>Company Overview</h3>
          {/* <LocationDropdown label="Gender" values={[{ name: 'Male' }, { name: 'Female' }, { name: 'Other' }]} required={true} name={nameValueDev.gender} value={values.gender} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.gender && errors.gender} /> */}

          <CascadingDropdown label="Domain of expertise" options={formDropdown?.domainOfExpertise} name={nameValueAgency.overview_areaOfExpertise} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched?.overview_areaOfExpertise && errors?.overview_areaOfExpertise} value={values?.overview_areaOfExpertise} required={true} />





          <LocationDropdown label="Company Size" values={[{ name: "10-50" }, { name: "51-200" }, { name: "201-500" }, { name: "More than 500" }]} name={nameValueAgency.overview_companySize} value={values.overview_companySize} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched?.overview_companySize && errors?.overview_companySize} />

          <AgencyPageInputfeild label="Company Experience" required={true} type="number" name={nameValueAgency.overview_industrialExperience} value={values.overview_industrialExperience} onChange={handleChange} onBlur={handleBlur} error={touched?.overview_industrialExperience && errors?.overview_industrialExperience} min={'0'} max={'99'} />

          <AgencyPageInputfeild label="Company Address" required={true} type="text" name={nameValueAgency.location_address} value={values.location_address} onChange={handleChange} onBlur={handleBlur} error={touched?.location_address && errors?.location_address} />



          {/* <AgencyPageDropdownFeild label="Country" values={["India", "Dubai", "USA", "Tokyo"]} />
          <AgencyPageDropdownFeild label="State" values={["Delhi", "Kolkata", "Mumbai"]} />
          <AgencyPageDropdownFeild label="District" values={["North-East", "North", "South", "South-West"]} />
          <AgencyPageDropdownFeild label="City" values={["Bhajanpura", "CP", "Rohini"]} /> */}


          <LocationDropdown label=" Country" values={location.countries} required={true} name={nameValueAgency.location_country} value={values.location_country} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched?.location_country && errors?.location_country} />

          <LocationDropdown label="State" values={location.states} required={true} name={nameValueAgency.location_state} value={values.location_state} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched?.location_state && errors?.location_state} />

          <LocationDropdown label="District" values={location.districts} required={true} name={nameValueAgency.location_district} value={values.location_district} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched?.location_district && errors?.location_district} />

          <LocationDropdown label="City" values={location.cities} required={true} name={nameValueAgency.location_city} value={values.location_city} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched?.location_city && errors?.location_city} />


          <AgencyPageInputfeild label="Pincode" required={true} type="text" name={nameValueAgency.location_pinCode} value={values.location_pinCode} onChange={handleChange} onBlur={handleBlur} error={touched?.location_pinCode && errors?.location_pinCode} />

          {/* <AgencyPageAssignToDropDown label="Assign to" required={true} objValues={assignVerificationTo} /> */}

          <LocationDropdown label=" Refer to" values={assignVerificationTo} required={true} name={nameValueAgency.assignVerificationTo} value={values.assignVerificationTo} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched?.assignVerificationTo && errors?.assignVerificationTo} />

          <button type='submit' onClick={handleSubmit}>Save Profile</button>
        </div>
      </div>
    </>
  )
}

export default CreateProfileAgency