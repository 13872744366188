// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ViewClient_ViewClient__zErBN {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    font-weight: 300;
    gap: 12px;
    padding: 12px 30px 30px 30px;
    h1 {
        margin: 10px 0px;
        font-size: 26px;
        width: 100%;
        text-align: center;
    }
    p {
        display: flex;
        color: rgb(98, 98, 98);
    }
    span {
        width: 160px;
        color: black;
        /* border: 2px dotted black; */
    }
    section {
        padding: 0;
        margin: 0;
        width: calc(100% - 160px);
        /* width: max-content; */
    }
}`, "",{"version":3,"sources":["webpack://./src/components/common/globalModal/components/ViewClient/ViewClient.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,sBAAsB;IACtB,gBAAgB;IAChB,SAAS;IACT,4BAA4B;IAC5B;QACI,gBAAgB;QAChB,eAAe;QACf,WAAW;QACX,kBAAkB;IACtB;IACA;QACI,aAAa;QACb,sBAAsB;IAC1B;IACA;QACI,YAAY;QACZ,YAAY;QACZ,8BAA8B;IAClC;IACA;QACI,UAAU;QACV,SAAS;QACT,yBAAyB;QACzB,wBAAwB;IAC5B;AACJ","sourcesContent":[".ViewClient {\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n    flex-direction: column;\n    font-weight: 300;\n    gap: 12px;\n    padding: 12px 30px 30px 30px;\n    h1 {\n        margin: 10px 0px;\n        font-size: 26px;\n        width: 100%;\n        text-align: center;\n    }\n    p {\n        display: flex;\n        color: rgb(98, 98, 98);\n    }\n    span {\n        width: 160px;\n        color: black;\n        /* border: 2px dotted black; */\n    }\n    section {\n        padding: 0;\n        margin: 0;\n        width: calc(100% - 160px);\n        /* width: max-content; */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ViewClient": `ViewClient_ViewClient__zErBN`
};
export default ___CSS_LOADER_EXPORT___;
