import React from 'react'
import { useSelector } from 'react-redux'

const ContactFormModal = () => {
    const message=useSelector((state)=>state?.globalModal?.data?.message)
    return (
        // <div>
        //     <h3 style={{textAlign:"center"}} >Sumbitted Successfully</h3>
        //     <p style={{fontWeight:"300"}} > 
        //         Success! Your message has been sent. We'll get back to you soon. Thank you for contacting us!
        //     </p>
        // </div>
        <div dangerouslySetInnerHTML={{ __html: message }} />
    )
}

export default ContactFormModal
