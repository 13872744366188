import React from 'react'
import styles from "./EmailVerification.module.css"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useSelector } from "react-redux"
import axios from 'axios'
import { BServer } from '../../../server/backend'

export default function ProvideEmailForVerification() {
    const authecationState = useSelector((state) => state.authentication)
    const isToken = (authecationState.token === "") ? false : true;   // if token exists, user has already logged in
    const { values, handleChange, handleSubmit, resetForm, errors } = useFormik({
        initialValues: {
            email: authecationState.userEmail,
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Required'),
        }),
        onSubmit: values => {
            alert(JSON.stringify(values, null, 2));

            axios.post(`${BServer}/sentUrlOnEmail`, {
                email: values.email,
            }).then(response => {
                console.log(response);
            }).catch(error => {
                console.log(error);
            });
        }
    });



    return (
        <div className={styles.EmailVerification} >
            <form onSubmit={handleSubmit} >
                <div className={styles.emailverificationcard} >
                    <h1>Provide Email For Verifyication</h1>
                    <div className={styles.inputclass}>
                        <label htmlFor="email">Enter Your Email: </label>
                        <input type='text' id='email' name='email' defaultValue={values.email} onChange={handleChange} disabled={isToken} /><br />
                        <span>
                            {errors?.email}
                        </span>
                    </div>
                    <button type='submit' >Submit</button>
                </div>
            </form>
        </div >
    )
}
