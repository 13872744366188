import React, { useEffect, useState } from 'react'
import styles from "./MyDeveloper.module.css"
import MyDeveloperCard from './components/MyDeveloperCard'
import { axiosInstanceWithToken } from '../../../../services/axios/axiosInterceptors'
import { BServer } from '../../../../server/backend'
import { useDispatch, useSelector } from 'react-redux'
import { viewAgencyAllDeveloperAction } from '../../../../Redux/actions/profile/viewProfile/viewAgencyAllDeveloperAction'
import HeadingTag from '../../../../components/common/childC/headingTag/HeadingTag'
import { useParams } from 'react-router-dom'
import { viewInHouseAllDeveloperAction } from '../../../../Redux/actions/profile/viewProfile/viewInHouseAllDeveloperAction'
import noprofileFound from "../../../../assets/images/no-result-found.png"

const MyDeveloper = () => {
  const myDevelopers = useSelector((state) => state?.viewAgencyAllDeveloper?.profiles)
  const authentication = useSelector((state) => state?.authentication)
  const params = useParams()
  console.log('myDevelopers', myDevelopers)
  const dispatch = useDispatch()
  useEffect(() => {
    if (params?.agencyId && (authentication?.userRole === 'admin' || authentication?.userRole === 'sAdmin')) {
      console.log('running one')
      dispatch(viewAgencyAllDeveloperAction(params?.agencyId))
    } else if (!params?.agencyId && (authentication?.userRole === 'admin' || authentication?.userRole === 'sAdmin')) {
      console.log('running two')

      dispatch(viewInHouseAllDeveloperAction())

    } else if (!params?.agencyId && authentication?.userRole == 'agency') {
      console.log('running three')

      dispatch(viewAgencyAllDeveloperAction(authentication?.userId))
    }
  }, [dispatch])

  return (
    <React.Fragment>

      {myDevelopers?.length > 0 ? (<div className={styles.MyDeveloper} >
        {myDevelopers.map((item) => (
          <MyDeveloperCard key={item?.createdAt} name={item?.personalInformation?.fullName} desgination={item?.professionalExperience?.professionalExperience_currentDesignation} skill={item?.professionalExperience?.professionalExperience_skills} address={`${item?.currentLocation?.currentLocation_state?.name}, ${item?.currentLocation?.currentLocation_country?.name}`} email={item?.personalInformation?.email} imgsrc={`${BServer}/uploads/${item?.personalInformation?.profilePicture}`} experience={item?.professionalExperience?.professionalExperience_totalExperienceInYear} github={item?.social?.social_git} linkedin={item?.social?.social_linkedin} userId={item?._id} />
        ))}</div>)
        :
        (<div className={styles.noProfilFound}>
          <img className={styles.noprofileFoundImage} src={noprofileFound} alt="Md Mudassar" />
          <p >No Data Found</p>
        </div>)

      }
    </React.Fragment>



  )
}

export default MyDeveloper