import React from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useSelector } from 'react-redux';

const UploadProgressBar = () => {
    const onUploadProgress = useSelector((state) => state?.onUploadProgress)
    const decideVariant = () => {
        if (onUploadProgress.percentCompleted<=25){
            return 'danger'
        }else if (onUploadProgress.percentCompleted<=50){
            return 'warning'
        }else if (onUploadProgress.percentCompleted<=75){
            return 'info'
        }else if (onUploadProgress.percentCompleted<=99){
            return 'success'
        }
    }
    return (
        <>
            
            {
                onUploadProgress.loading &&
                <ProgressBar animated now={onUploadProgress?.percentCompleted} label={`${onUploadProgress?.percentCompleted}%`} variant={decideVariant()} style={{height:'.7rem'}}/>

            }
            
        </>

    )
}

export default UploadProgressBar