// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordReset_PasswordReset__BysMV {
    h1 {
        font-size: x-large;
        text-align: center;
    }
    input {
        width: 100%;
        height: 40px;
        padding-left: 10px;
    }
    button {
        width: 100%;
        background-color: #681487;
        border: none;
        color: whitesmoke;
        margin-top: 10px;
        height: 40px;
    }
}


.PasswordReset_isShow__bKKze {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    margin-top: 10px;
    input {
        width: 15px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/common/globalModal/components/PasswordReset/PasswordReset.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,kBAAkB;QAClB,kBAAkB;IACtB;IACA;QACI,WAAW;QACX,YAAY;QACZ,kBAAkB;IACtB;IACA;QACI,WAAW;QACX,yBAAyB;QACzB,YAAY;QACZ,iBAAiB;QACjB,gBAAgB;QAChB,YAAY;IAChB;AACJ;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,QAAQ;IACR,gBAAgB;IAChB;QACI,WAAW;IACf;AACJ","sourcesContent":[".PasswordReset {\n    h1 {\n        font-size: x-large;\n        text-align: center;\n    }\n    input {\n        width: 100%;\n        height: 40px;\n        padding-left: 10px;\n    }\n    button {\n        width: 100%;\n        background-color: #681487;\n        border: none;\n        color: whitesmoke;\n        margin-top: 10px;\n        height: 40px;\n    }\n}\n\n\n.isShow {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 6px;\n    margin-top: 10px;\n    input {\n        width: 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PasswordReset": `PasswordReset_PasswordReset__BysMV`,
	"isShow": `PasswordReset_isShow__bKKze`
};
export default ___CSS_LOADER_EXPORT___;
