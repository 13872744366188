import React from 'react'
import {useSelector} from "react-redux";
const BenchResourcesModal = () => {
  const data  = useSelector((state) => state?.globalModal?.data)
  // console.log(data)
  return (
    <div>
      {/* hello this is modal  */}
      <img src={data?.resume} alt="Md Mudassar" style={{width:"600px"}} />
    </div>
  )
}

export default BenchResourcesModal
