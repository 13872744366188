import React from 'react'
import styles from "../Confirmation/CreateProfileSubmission.module.css"
import { useDispatch } from "react-redux"
import { useFormik } from 'formik';
import { CreateAdminvalidation } from '../../../../../assets/yupValidation/CreateAdminvalidation';
import { axiosInstanceWithToken, axiosInstanceWithoutToken } from '../../../../../services/axios/axiosInterceptors';
import { BServer } from '../../../../../server/backend';
import { toast } from 'react-toastify';
import { getAllMemberOfSingleRoleAction } from '../../../../../Redux/actions/member/getAllMemberOfSingleRoleAction';
import { IoMdClose } from 'react-icons/io';

const CreateAdmin = () => {


    const { values, handleChange, handleSubmit, resetForm, errors } = useFormik({
        initialValues: {
            email: ''
        },

        validationSchema: CreateAdminvalidation,
        onSubmit: async (values, resetForm) => {
            const { email } = values;
            try {
                const result = await axiosInstanceWithToken.post(`${BServer}/admin/signupForAdmin`, { email: email }).then((res) => {
                    console.log('RES 11', res)
                    if (res.data.errorCode === 200) {
                        dispatch({ type: "HIDE_MODAL" })
                        toast.success(res.data.message)
                        dispatch(getAllMemberOfSingleRoleAction('admin', '', 1, 10))

                    } else {
                        toast.error(res.data.message)

                    }
                })


            } catch (error) {
                console.log(error)

                return error

            }
        },
    });
    const dispatch = useDispatch()

    return (
        <form action="" onSubmit={handleSubmit} onReset={resetForm} >

            <div className={styles.SubmissionModal} >
                <div className={styles.closebutton} onClick={() => dispatch({ type: 'HIDE_MODAL' })} ><IoMdClose size={"24px"} /></div>
                <h3 style={{ fontSize: "24px" }} >Create Admin</h3>
                <p>
                    <label htmlFor="">Enter Email</label><span> *</span> <br />
                    <input type="email" name='email' value={values.email} onChange={handleChange} />
                    <small className={styles.errorMessage} >{errors.email}</small>
                </p>

                <div className={styles.SubmissionModalActions}>
                    <button style={{ width: "120px" }} >Continue</button>
                </div>
            </div>
        </form>
    )
}

export default CreateAdmin
