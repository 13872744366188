import * as Yup from 'yup'
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
export const signinValidation = Yup.object().shape({
    email: Yup
        .string()
        .email('Email is invalid')
        .test('Email is invalid', (value) => {
            return value.split('@')[1]?.includes('.')
        })
        .required('Email is required'),

    password: Yup
        .string()
        // .test('password must contains all these', `password must contain 1.Minimum eight characters. 2.At least one uppercase letter. 3. One lowercase letter. 4.One number and one special character`, (value) => {
        //     return passwordRegex.test(value)
        // })
        .required('Password is required')

})