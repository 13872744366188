import React, { useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { FaRegEye , FaRegEyeSlash } from "react-icons/fa6";

const InputBootstrap = (props) => {
    const { type, placeholder, className, value, name, onChange, onBlur, label, errorClassName, isInvalid, error ,isPasswordFeild } = props
    const [showPassword , setShowPassword] = useState(type)
    const showPasswordHandler = () => {
        setShowPassword(prevType => prevType === 'password' ? 'text' : 'password')
    }
    return (
        <Form.Group className="mb-3">
            <Form.Label>{label}</Form.Label>
            <InputGroup>
                <Form.Control type={ isPasswordFeild ? showPassword : type } placeholder={placeholder} className={className} name={name} value={value} onChange={onChange} onBlur={onBlur} isInvalid={isInvalid} />
                {isPasswordFeild && <InputGroup.Text id="basic-addon2" onClick={showPasswordHandler} >{showPassword === 'password' ? <FaRegEyeSlash /> : <FaRegEye />}</InputGroup.Text>}
            </InputGroup>
            {

                error &&
                <span className={errorClassName}>{error}</span>

            }
        </Form.Group>
    )
}

export default InputBootstrap