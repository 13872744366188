// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavbarDropdown_itemsdrop__JDkV8 {
    font-size: 15px;
    color: #000 !important;
    font-family: 'Roboto Condensed', sans-serif;

    color: #000 !important;
    transition: none !important;
    
}
.NavbarDropdown_NavDropDown__co-oA {
    color: #000 !important;
    transition: none !important;

}`, "",{"version":3,"sources":["webpack://./src/components/common/navbar/component/dropdown/NavbarDropdown.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,sBAAsB;IACtB,2CAA2C;;IAE3C,sBAAsB;IACtB,2BAA2B;;AAE/B;AACA;IACI,sBAAsB;IACtB,2BAA2B;;AAE/B","sourcesContent":[".itemsdrop {\n    font-size: 15px;\n    color: #000 !important;\n    font-family: 'Roboto Condensed', sans-serif;\n\n    color: #000 !important;\n    transition: none !important;\n    \n}\n.NavDropDown {\n    color: #000 !important;\n    transition: none !important;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemsdrop": `NavbarDropdown_itemsdrop__JDkV8`,
	"NavDropDown": `NavbarDropdown_NavDropDown__co-oA`
};
export default ___CSS_LOADER_EXPORT___;
