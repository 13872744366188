import CreateProfileAdminSubmission from "./components/Confirmation/CreateProfileAdminSubmission";
import CreateProfileAgencySubmission from "./components/Confirmation/CreateProfileAgencySubmission";
import CreateProfileDeveloperSubmission from "./components/Confirmation/CreateProfileDeveloperSubmission";
import CreateAdmin from "./components/SuccefullSignup/CreateAdmin";
import SuccessfullSignup from "./components/SuccefullSignup/SuccessfullSignup";
import JobDetails from "./components/Jobdetails/JobDetails";
import Hello from "./components/hello/Hello";
import VerifyMobile from "./components/verifyMobile/VerifyMobile";
import PasswordReset from "./components/PasswordReset/PasswordReset";
import ContactForm from "./components/HireDevForm/ContactForm";
import ViewClient from "./components/ViewClient/ViewClient";
import BenchResourcesModal from "./components/BenchResourcesModal/BenchResourcesModal";
import ContactFormModal from "./components/ContactFormModal/ContactFormModal";
import EmailVerificationModal from "./components/EmailVerificationModal/EmailVerificationModal";
import AgencyProfileConfirmation from "./components/agencyProfileConfirmation/AgencyProfileConfirmation";
import LoginWithOtp from "./components/loginWithOtp/LoginWithOtp";

export const globalModalComponents = {
    Hello: <Hello />,
    VerifyMobile:<VerifyMobile/>,
    LoginWithOtp:<LoginWithOtp/>,
    SuccessfullSignup:<SuccessfullSignup/>,
    CreateProfileAgencySubmission:<CreateProfileAgencySubmission/>,
    CreateProfileAdminSubmission:<CreateProfileAdminSubmission/>,
    CreateProfileDeveloperSubmission:<CreateProfileDeveloperSubmission/>,
    CreateAdmin:<CreateAdmin/>,
    // SuccessfullSignupForAdmin:<SuccessfullSignupForAdmin/>,
    JobDetails:<JobDetails/>,
    PasswordReset:<PasswordReset/>,
    ContactForm:<ContactForm/>,
    ViewClient:<ViewClient/>,
    BenchResourcesModal : <BenchResourcesModal/>,
    ContactFormModal : <ContactFormModal/>,
    EmailVerificationModal : <EmailVerificationModal/>,
    AgencyProfileConfirmation:<AgencyProfileConfirmation/>,

}