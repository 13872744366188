import React, { memo, useCallback, useEffect, useMemo, useState, } from 'react'
import styles from "./CreateProfileDev.module.css"

const CascadingDropdown = (props) => {
    const { options, setFieldValue, name, onBlur, error, value, label, required } = props
    const [items, setItems] = useState(value?.map((i) => i._id))
    const onChangeHandle = (e) => {
        // if (!items?.includes(JSON.parse(e.target.value))) {
        //     setItems([...items, JSON.parse(e.target.value)])
        console.log('eeeeeee', e.target.value)
        if (!items?.includes(e.target.value)) {
            setItems([...items, e.target.value])

            setFieldValue(name, [...options.filter((i) => items?.includes(i._id)), ...options.filter((i) => e.target.value == i._id)])

        }
    }
    console.log('items 18 18', items)
    const removeItem = (idx) => {

        const updatedItems = [...items];
        updatedItems.splice(idx, 1);
        setItems(updatedItems);
        setFieldValue(name, [...options.filter((i) => updatedItems?.includes(i._id))])
    }

    return (
        <div className={styles.AgencyPageCascadingDropdown}>
            <label htmlFor="">{label}{required && <span> *</span>}</label>
            <select onChange={onChangeHandle} onBlur={onBlur} name={name}  >
                {
                    options && options.map((item) => (
                        <option key={item.name} value={item._id}>{item.name}</option>
                    ))
                }

            </select>
            {
                error &&
                <div className={styles.AgencyPageInputfield_error_msg}>{error}</div>
            }
            {value && <div className={styles.CascadingPageValues} >
                {value && value.map((item, index) => (
                    <span key={item.name}>{item.name}

                        <button onClick={() => removeItem(index)}>
                            X
                        </button>

                    </span>
                ))}
            </div>}
        </div>
    )
}

export default memo(CascadingDropdown)


// const result = options.filter((i) => items?.includes(i._id))

// useEffect(() => {
//     setFieldValue(setFieldValue(name, options.filter((i) => items?.includes(i._id))))
// }, [items])
// const asia=useMemo(()=>setFieldValue(name, options.filter((i) => items?.includes(i._id))),[])