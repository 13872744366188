const initialValuejobPost = {
     // basic
     title: '',
     description: '',
     openingType: {},
     openings: 1,
 
     // profession
     domaiOfExpertise:{},
     skills:[],
     totalExperienceInYear:{},
 
     // amount
     rate:{},
     min:0,
     max:0,
 
     // interval
     start:'',
     end:'',
 
     // location
     location_country:{},
     location_state:{},
     location_district:{},
     location_city:{},
 
     // pointOfContact
     emailTo:'',
     mobileTo:null,

    //  others
     jobType:{},
     shiftType:{},
     visibility:{},
     benifits:[],
     makeItHot:false
}

const nameValueJobPost = {

    // basic
    title: 'title',
    description: 'description',
    openingType: 'openingType',
    openings: 'openings',

    // profession
    domaiOfExpertise:'domaiOfExpertise',
    skills:'skills',
    totalExperienceInYear:'totalExperienceInYear',

    // amount
    rate:'rate',
    min:'min',
    max:'max',

    // interval
    start:'start',
    end:'end',

    // location
    location_country:'location_country',
    location_state:'location_state',
    location_district:'location_district',
    location_city:'location_city',

    // pointOfContact

    emailTo:'emailTo',
    mobileTo:'mobileTo',

    // others
    jobType:'jobType',
    shiftType:'shiftType',
    visibility:'visibility',
    benifits:'benifits',
    makeItHot:'makeItHot'

}

module.exports = { initialValuejobPost, nameValueJobPost }