import React from 'react'
import {useDispatch} from 'react-redux'
import { globalModal } from '../../../Redux/actions/globalModal'

const BenchresourcesTable = (props) => {
    const dispatch = useDispatch()
    const resumeClickHandler = (resume) => {
        dispatch(globalModal({component:'BenchResourcesModal',closeButton:true,size:'md',data :{resume}}))
    }
    return (
        <div>
            <table className="custom-table">
                <thead>
                    <tr>
                        <th>Resource Name</th>
                        <th>Experience</th>
                        <th>Tech Stack</th>
                        <th>Screening Test Marks</th>
                        <th>Technical Assessment</th>
                        <th>Resume</th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map(e => {
                        return (
                            <tr className="toggler toggler1">
                                <td>{e.resourceName}</td>
                                <td>{e.experience}+ yrs</td>
                                <td>{e.techStack.map(ts => <span key={ts}>{ts},&nbsp;</span>)}</td>
                                <td><span className="stars" style={{ color: "#ffd130" }}>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                </span>
                                    {e.screeningTestMarks}/10</td>
                                <td><span className="stars" style={{ color: "#ffd130" }}>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fas fa-star-half-alt"></i>
                                    <i className="fa-regular fa-star"></i>
                                </span>
                                    {e.technicalAssessment}/10
                                </td>
                                <td className="resume-sec" onClick={resumeClickHandler.bind(null,e.resume)} ><img src={e.resume}
                                     alt="Md Mudassar" />
                                </td>
                            </tr>
                            )
                        })}

                </tbody>


            </table>
        </div>
    )
}

export default BenchresourcesTable
