import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import styles from "./MyAdmins.module.css"
import SwitchExample from '../../../components/common/childA/switchbuttons/SwitchButton'
import Table from '../../../components/common/childA/table/MyTable'
import Pagination from '../../../components/common/childA/pagination/MyPagination'
import CreateAdmin from '../../forSuperAdmin/createAdmin/CreateAdmin';
import { useDispatch, useSelector } from "react-redux"
import { globalModal } from '../../../Redux/actions/globalModal';
import { MdAddCircleOutline } from "react-icons/md";
import MyTable from '../../../components/common/childA/table/MyTable';
import { fakeDataAdmins } from '../../../components/common/childA/usersapi/UserApis';
import { getAllMemberOfSingleRoleAction } from '../../../Redux/actions/member/getAllMemberOfSingleRoleAction';
import MyPagination from '../../../components/common/childA/pagination/MyPagination';
import stylesTable from "../../../components/common/childA/table/MyTable.module.css"
import { BServer } from '../../../server/backend';
import { toggleStatusAdminAction } from '../../../Redux/actions/member/toggleStatusAdminAction';
import noprofileFound from "../../../assets/images/no-result-found.png"

const MyAdmins = () => {
  const dispatch = useDispatch()
  const [searchInput, setSearchInput] = useState('')
  const allMember = useSelector((state) => state?.getAllMemberOfSingleRoleReducer)
  const TableHead = ["SN", 'Picture', "Member Id", "Name", "Desgination", "Email", "Phone Number", "Status"]
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  const totalItems = allMember?.length;
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const tableData = allMember?.documents?.slice(firstIndex, lastIndex)
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const inputOnChangeHandle = (e) => {
    setSearchInput(e.target.value)
  }
  const CreateAdminHandler = () => {
    dispatch(globalModal({ component: 'CreateAdmin' }))
  }

  useEffect(() => {
    dispatch(getAllMemberOfSingleRoleAction('admin', searchInput, currentPage, 10))
  }, [searchInput,currentPage])
  const handleSearch=()=>{
    dispatch(getAllMemberOfSingleRoleAction('admin', searchInput, currentPage, 10))

  }
 

  
  return (
    <div>
      <section className={styles.banner}>
        <div className="container-fluid ">
          <div className="row align-items-center justify-content-center">
            <div className={`${styles.head_st} ${styles.banner} `}>
              <h2>View Admins Lists</h2>
              <div className={styles.searchbar}>
                <input type="text" placeholder="Enter name or member id" name='searchInput' value={searchInput} onChange={inputOnChangeHandle} />

                <button onClick={handleSearch}>Search</button>

              </div>
              <button className={styles.CreateAdminButton} onClick={CreateAdminHandler} > <MdAddCircleOutline size={"18px"} /> Create Admin</button>
              {/* <SearchBar/> */}
            </div>
          </div>
        </div>
      </section>

      <div className={styles.container}>
        {/* <MyTable
          tableHead={["SN", 'Picture', "Member Id", "Admin Name", "Desgination", "Email", "Phone Number", "Status"]}
          tableBody={["memberId", 'profileId.personalInformation.profilePicture', "memberId", "profileId.personalInformation.fullName", "profileId.professionalExperience.professionalExperience_currentDesignation", "email", "mobile", "isActive"]}
          data={tableData}
        /> */}

        <div className={stylesTable.container}>
          {tableData.length > 0 ? (<table responsive="sm" className={stylesTable.custom_table}>
            <thead>
              <tr>
                {TableHead.map(val => {
                  return (
                    <th key={val}>{val}</th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((i, idx) => {
                return (
                  // <MyTableRow key={user?.memberId} userData={user} tableBody={tableBody} click={props.click} clickFunc={props.clickFunc} />
                  <tr className={!i['profileId._id'] && `bg-danger`} style={{ cursor: !i['profileId._id'] && 'not-allowed' }}>
                    <td><span>{idx + 1}</span></td>
                    <td><span>
                      <img
                        src={
                          i['profileId.personalInformation.profilePicture'] ?
                            `${BServer}/uploads/${i['profileId.personalInformation.profilePicture']}`
                            :
                            `https://cdn-icons-png.flaticon.com/512/6596/6596121.png`
                        }
                        alt="Md Mudassar"
                        style={{ width: 40, height: 40, cursor: !i['profileId._id'] ? 'not-allowed' : 'pointer' }}
                        onClick={() => i['profileId._id'] && window.open(`/admin/profile-${i?.userRole}/${encodeURIComponent(JSON.stringify(i?._id))}`, '_blank')}
                      />
                    </span></td>
                    <td><span>{i?.memberId}</span></td>
                    <td><span>{i?.name ? i?.name : 'No data'}</span></td>
                    <td><span>{i['profileId._id'] ? i['profileId.professionalExperience.professionalExperience_currentDesignation'] : 'No data'}</span></td>

                    <td><span>{i['email']}</span></td>
                    <td><span>{i['mobile'] ? i['mobile'] : 'No data'}</span></td>

                    <td>
                      <span
                        onClick={() => dispatch(toggleStatusAdminAction(i?._id, i?.memberId, i?.userRole, searchInput, currentPage, itemsPerPage))}
                        style={{ cursor: 'pointer', color: i?.isActive ? 'green' : 'red' }}
                      >
                        {/* <Form>
                          <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            label=""
                            checked={i?.isActive}
                            onClick={() => dispatch(toggleStatusAdminAction(i?._id, i?.memberId, i?.userRole, searchInput, currentPage, itemsPerPage))}
                          // disabled={}

                          />

                        </Form> */}
                        {
                          i?.isActive ? 'Active' : 'Inactive'
                        }
                      </span>
                    </td>

                  </tr>
                )
              })}
            </tbody>
          </table>): <div className={styles.noProfilFound}>
            <img className={styles.noprofileFoundImage} src={noprofileFound} alt="Md Mudassar" />
            <p >No Data Found</p>
          </div>}

        </div>

        {tableData?.length > 0 && (<div className='a d-flex justify-content-center'>

          <MyPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />

        </div>)}
      </div>


    </div>
  )
}

export default MyAdmins