import Form from 'react-bootstrap/Form';
import styles from './SwitchButtons.module.css'

function SwitchExample(props) {
  const{disabled}=props
  return (
    <div className={styles.switch_btn}>
    <Form>
      <Form.Check // prettier-ignore
        type="switch"
        id="custom-switch"
        label=""
        disabled={disabled}
        style={{cursor:disabled?'not-allowed':'pointer'}}
      />
      
    </Form>
    </div>
    
  );
}

export default SwitchExample;