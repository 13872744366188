import styles from "../Agencies.module.css"
import { useState } from "react"
import { FaLinkedin, FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { GoGlobe } from "react-icons/go";
import { BsFillInfoCircleFill ,BsClockHistory } from "react-icons/bs";
import { MdGroups ,MdBlockFlipped } from "react-icons/md";
import { MdDone } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";

const AgencyCard = props => {

    const { name, email, phone, NumberOfEmployee, linkedin, website, establishmentYear, address, imgsrc, userId, info } = props
        
    const [isBlock,setisBlock] = useState(false)

    const handleBlockChange = () => {
        setisBlock(!isBlock)
    }

    return (
        <div className={styles.AgencyCard}>
            {isBlock && <div className={styles.BlockAgencyCard}>
                <button onClick={handleBlockChange} >Unblock</button>
            </div>}
            <div className={styles.MyDeveloperCardImage}>
                <img src={imgsrc} alt="Md Mudassar" />
            </div>
            <div className={styles.MyDeveloperCardDetail}>
                <h2>{name}</h2>

                <p>{email}</p>
                {phone && <p>{phone}</p>}
                <p> <small> <BsClockHistory size={"14px"} /></small>{establishmentYear} Years</p>
                <p> <small> <IoLocationSharp size={"14px"} /></small>{address}</p>
                <p> <small> <MdGroups size={"14px"} /></small>{NumberOfEmployee} employees</p>
                <div className={styles.MydeveloperCardActions}>
                    <div className={styles.MyDeveloperCardDetailSocial}>
                        <a href={`//${linkedin}`} target="_blank"><FaLinkedin /></a>
                        <a href={`//${website}`} target="_blank"><GoGlobe /></a>
                        <a href={`//${info}`} target="_blank" ><BsFillInfoCircleFill /></a>
                    </div>
                    <div className={styles.MyDeveloperCardDetailSocial}>
                        <button title="Accept" ><MdDone /></button>
                        <button title="Decline" ><RxCross1 /></button>
                        <button title="Block" onClick={handleBlockChange} > <MdBlockFlipped /> </button>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default AgencyCard;