import React from 'react'
import styles from "./MyTable.module.css"
import SwitchExample from '../switchbuttons/SwitchButton'
import { Table } from 'react-bootstrap'
import Resume from '../../../../assets/images/resume_demo.webp'
import { getUsers } from '../usersapi/UserApis';
import userEvent from '@testing-library/user-event';
import MyTableRow from './MyTableRow'

const MyTable = (props) => {

const {tableHead,tableBody,data}=props
console.log('data in MyTable',data)
  return (
    <div className={styles.container}>
      <table responsive="sm" className={styles.custom_table}>
        <thead>
          <tr>
            {tableHead.map(val => {
              return (
                <th key={val}>{val}</th>
              )
            })}
          </tr>
        </thead>

        <tbody>
            {data.map(user => {
              return(
                <MyTableRow  key={user?.memberId} userData={user} tableBody={tableBody} click={props.click} clickFunc={props.clickFunc} />
              )
            })}
        </tbody>



      </table>

    </div>

  );
};

export default MyTable