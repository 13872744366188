import React from 'react'
import styles from './Partners.module.css'
import heroimg from '../../../assets/images/partners-img.jpg'
import Clientele from '../../common/landingPage/components/Clientele.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import carilogo from '../../../assets/images/cari-logo.png'
import { Link } from 'react-router-dom'


const Partners = () => {
  return (
    <div>

      <div className={styles.herobnr}>
        <h2>
          Onboard Your Talents on
        </h2>
        <h1>
          Talnfinium
        </h1>
        <p>
          Create a talent bench for your developers hassle-free and <br /> help them work with leading companies.
        </p>
        <div className={styles.herobnr_actions}>
          <Link className={styles.btnbnr1} to='/signup' >Become a Partner</Link>

          <a href="/apply-job">
            <button className={styles.btnbnr2}>Active Jobs</button>
          </a>

        </div>
        <h3>Our world-class partner ecosystem includes:</h3>
      </div>

      <div className={styles.client_sec}>
        <Clientele />
      </div>


      <div className="choose_us">
        <div className="feat bg-gray pt-3 pb-4">
          <div className={styles.container}>
            <div className="row">
              <div className={`col-sm-12 ${styles.section_head}`}>
                <h4>Let's Grow – Together</h4>
                {/* <p>When you choose us, you'll feel the benefit of 10 years' experience of Web Development. Because we know the digital world and we know that how to handle it. With working knowledge of online, SEO and social media.</p> */}
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className={styles.item}>
                  <h6>Boost your revenue</h6>
                  <p>We use the latest technology for the latest world because we know the demand of people around the world.</p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className={styles.item}>
                  <h6>Talent Bench</h6>
                  <p>You can streamline and improve your hiring process with the help of our Talent Bench.</p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className={styles.item}>
                  <h6>Expand your network</h6>
                  <p>Don't struggle to discover the ideal developer for your team with the help of Talent Infnium.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className={styles.item}>
                  <h6>Win more clients</h6>
                  <p>Get business referrals, co-marketing, sales enablement, exclusive events, and more.</p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className={styles.item}>
                  <h6>Scale your agency</h6>
                  <p>We are always Access agency-specific tools, certification for employees, and dedicated support.</p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className={styles.item}>
                  <h6>700+</h6>
                  <p>If our customer has any problem and we have Active agency-managed brands.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='text-center pb-5'>
          {/* <button >Become a Partner</button> */}
          <Link className={styles.btnpart} to='/signup' >Become a Partner</Link>
        </div>

      </div>

      <div>
        <div className={` d-flex ${styles.benfitsec} `}>
          <div className='col-6 '>
            <h3>
              Financial Responsibility
            </h3>
            <p>
              Financially, partnership often proves lucrative, with partners reaping rewards through profit-sharing arrangements or equity ownership. This not only incentivizes hard work but also fosters a sense of collective responsibility as partners share both the successes and challenges of the venture.
            </p>
          </div>
          <div className='col-6 '>
            <h3>
              Decision-Making Power:
            </h3>
            <p>
            Decision-making power in a staffing company typically lies with its management team or executives, who are responsible for determining the direction, strategies, and policies of the organization. Here's a breakdown of decision-making power within a staffing company. Management makes decisions.
            </p>
          </div>
        </div>
        <div className={` d-flex ${styles.benfitsec} `}>
          <div className='col-6'>
            <h3>
              Skills Enhancement:
            </h3>
            <p>
            Skills enhancement in the staffing industry involves refining recruitment and candidate assessment abilities, honing communication and networking prowess, mastering sales techniques, embracing technology, staying updated on industry trends, analyzing data effectively, fostering adaptability, and cultivating leadership qualities. 
            </p>
          </div>
          <div className='col-6'>
            <h3>
              Long-Term Stability:
            </h3>
            <p>
            Long-term stability in the staffing industry necessitates strategic planning, diversification, and fostering strong client relationships. It involves adapting to market fluctuations, embracing innovation, and investing in technology and talent development. Building a resilient business model, prioritizing customer satisfaction. 
            </p>
          </div>
        </div>
      </div>

      <div className={`d-flex mt-5 ${styles.cntsection}`}>
        <div className=''>
          <h3>
            Explore our ecosystem of partners
          </h3>
          <p>
            Discover how Talnfinium partners help organisations of all sizes.
          </p>
          {/* <button>View Partners</button> */}
          <Link className={styles.btnbnr3} to='https://www.linkedin.com/in/md-mudassar' >View Partners</Link>
        </div>

        <div className=''>
          <h5>
            Already a partner?
          </h5>
          <p>
            Manage your benefits in the partner portal.
          </p>
          {/* <a href='/signin'>Log in</a> */}
          <Link to='/signin' >Login</Link>
        </div>

        <div className=''>

          <h5>
            View partner requirements
          </h5>
          <p>
            See what it takes to become a Talnfinium Partner.
          </p>
          {/* <a href='/joblisting'>View requirements</a> */}
          <Link to='/apply-job' >View requirements</Link>
        </div>

      </div>


     


















    </div>
  )
}

export default Partners