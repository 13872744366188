import React, { useEffect } from "react";
import "./ViewProfileDev.css";
import linkedin from "../../../../assets/images/linkedin.png";
import github from "../../../../assets/images/github_270798.png";
import { useDispatch, useSelector } from "react-redux";
import { viewProfileAgencyAction } from "../../../../Redux/actions/profile/viewProfile/viewProfileAgencyAction";
import { viewProfileDevAction } from "../../../../Redux/actions/profile/viewProfile/viewProfileDevAction";
import { BServer } from "../../../../server/backend";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaPhoneAlt, FaUsers, FaLinkedin, FaGithub } from "react-icons/fa";
import { viewInHouseSingleDeveloperAction } from "../../../../Redux/actions/profile/viewProfile/viewInHouseSingleDeveloperAction";
function useQueryParams() {
  const queryParams = new URLSearchParams(useLocation().search);
  const paramsObject = {};

  for (const [key, value] of queryParams) {
    paramsObject[key] = value;
  }

  return paramsObject;
}

const ViewProfileDev = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const userIdFromUseLocation = useLocation()
  const userIdFromParams = useParams()
  const authentication = useSelector((state) => state?.authentication)
  const query = useQueryParams()
  const { loading, profileData } = useSelector(
    (state) => authentication?.userRole == 'developer' ? state?.myProfile : state?.viewProfileDev
  );
  console.log('profileData', profileData)
  console.log('query', query)

  const returnMobile = () => {
    if (authentication?.userRole === 'developer') {
      return profileData?.userId?.mobile ? `+91-${profileData?.userId?.mobile}` : `+91-No data`
    } else if (authentication?.userRole === 'agency' || query?.category === 'inHouse' || query?.category === 'agenciesDeveloper') {
      return profileData?.personalInformation?.mobile ? `+91-${profileData?.personalInformation?.mobile}` : `+91-No data`
    } else if (query?.category === 'candidate') {
      return profileData?.userId?.mobile ? `+91-${profileData?.userId?.mobile}` : `+91-No data`
    }
  }

  useEffect(() => {
    // we have not called api for developer profile if a developer open his page bcz it already exist in store

    if (authentication?.userRole === 'agency') {

      dispatch(viewProfileDevAction(JSON.parse(decodeURIComponent(userIdFromParams?.userId))));

    } else if (authentication?.userRole === 'admin' || authentication?.userRole === 'sAdmin') {
      if (query?.category === 'inHouse') {
        console.log('hi 47')
        dispatch(viewInHouseSingleDeveloperAction(JSON.parse(decodeURIComponent(userIdFromParams?.userId))))
      } else if (query?.category === 'agenciesDeveloper') {
        console.log('hi 47')
        dispatch(viewProfileDevAction(JSON.parse(decodeURIComponent(userIdFromParams?.userId)), query?.category));
      } else if (query?.category === 'candidate') {
        dispatch(viewProfileDevAction(JSON.parse(decodeURIComponent(userIdFromParams?.userId))));

      }
    }

  }, []);

  return (
    <div className="container-fluid whole-sec">
      <div className="main-body">
        <div className="row gutters-sm">
          <div className="col-md-4 mb-3 mt-5">
            <div className="profile-card-body">
              <div className="d-flex flex-column align-items-center text-center">
                <img
                  src={`${BServer}/uploads/${profileData?.personalInformation?.profilePicture}`}
                  alt="Admin"
                  className="rounded-circle mt-3"
                  style={{ aspectRatio: "1/1", width: "150px" }}
                />
                <div className="cat-exper mt-3">
                  <h5>{profileData?.personalInformation?.fullName}</h5>
                  <h6 className=" mb-0">
                    {
                      profileData?.userId?.memberId

                    }
                  </h6>
                  <h6 className=" mb-0">
                    {
                      profileData?.professionalExperience
                        ?.professionalExperience_currentDesignation
                    }
                  </h6>
                  <p className="profile-add">
                    {`
                    ${profileData?.currentLocation?.currentLocation_city?.name},
                    ${profileData?.currentLocation?.currentLocation_district
                        ?.name
                      }, 
                    ${profileData?.currentLocation?.currentLocation_state?.name}, 
                    ${profileData?.currentLocation?.currentLocation_country
                        ?.name
                      }, 
                    ${profileData?.currentLocation?.currentLocation_pinCode}
                    `}
                  </p>
                  {/* <button className="btn btn-follow">Follow</button>
                    <button className="btn btn-msg">Message</button> */}
                </div>
              </div>
              {/* </div> */}
            </div>
            <div className="profile-card-social mt-3">
              <h5 style={{ marginLeft: "15px" }}>Social Links</h5>
              <ul className="list-group list-group-flush mt-3">
                <li className="list-group-item d-flex align-items-center flex-wrap">
                  {/* <img
                    src={linkedin}
                    style={{ width: "7%", marginRight: "5px" }}
                  />
                  <a href="#" className="link-text-social">
                    {profileData?.social?.social_linkedin}
                  </a> */}
                  <FaLinkedin style={{ width: "7%", marginRight: "5px" }} />
                  <a href={`//${profileData?.social?.social_linkedin}`} target="_blank" className="link-text-social">
                    {profileData?.social?.social_linkedin}
                  </a>
                </li>
                <li className="list-group-item d-flex align-items-center flex-wrap">
                  {/* <img
                    src={github}
                    style={{ width: "7%", marginRight: "5px" }}
                  />
                  <a href="#" className="link-text-social">
                    {profileData?.social?.social_git}
                  </a> */}
                  <FaGithub style={{ width: "7%", marginRight: "5px" }} />
                  <a href={`//${profileData?.social?.social_git}`} target="_blank" className="link-text-social">
                    {profileData?.social?.social_git}
                  </a>
                </li>
                <div className="ViewProfileDevSocialLinksButton mt-3 ">
                  <div className="w-50">
                    {profileData?.userId?._id === authentication?.userId &&

                      <span className="btn btn-info " onClick={() => navigate('profile/edit ')}>
                        Edit Profile
                      </span>
                    }
                  </div>

                  {/* <div className="w-50">
                      {profileData?.userId?._id === authentication?.userId &&

                        <span className="btn btn-info px-1" >
                          Reset Password
                        </span>
                      }
                    </div> */}

                </div>

              </ul>
            </div>
          </div>
          <div className="col-md-8">
            <div className="mb-3 mt-5">
              <div className="profile-card-body">
                <h4>Personal Details</h4>
                <div className="row mt-3">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Full Name</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {profileData?.personalInformation?.fullName}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">DOB</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {new Date(
                      profileData?.personalInformation?.dateOfBirth
                    ).toLocaleDateString()}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Gender</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {profileData?.personalInformation?.gender?.name}
                  </div>
                </div>
                <hr />

                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Phone</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">




                    {returnMobile()}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">E-mail</h6>
                  </div>

                  <div className="col-sm-9 link-text-social">

                    {authentication?.userRole === 'developer' ?
                      profileData?.userId?.email
                      :
                      profileData?.personalInformation?.email

                    }
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Address</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {profileData?.currentLocation?.currentLocation_city?.name}, &nbsp;
                    {
                      profileData?.currentLocation?.currentLocation_district
                        ?.name
                    }
                    ,&nbsp;{profileData?.currentLocation?.currentLocation_state?.name}
                    ,&nbsp;
                    {
                      profileData?.currentLocation?.currentLocation_country
                        ?.name
                    }
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Pincode</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {profileData?.currentLocation?.currentLocation_pinCode}
                  </div>
                </div>
                {/* <hr /> */}
                {/* <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Experience</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {`
                    ${profileData?.professionalExperience?.professionalExperience_totalExperienceInYear} ${profileData?.professionalExperience?.professionalExperience_totalExperienceInYear > 1 ? `years` : `year`} 
                    
                    
                    `}
                  </div>
                </div> */}
              </div>
            </div>

            <div className="mb-3 mt-2">
              <div className="profile-card-body">
                <h4>Professional Experience</h4>
                <div className="row mt-3">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Current Role</h6>
                  </div>
                  <div className="col-sm-8 link-text-social">
                    {
                      profileData?.professionalExperience
                        ?.professionalExperience_currentDesignation
                    }
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Organization</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {
                      profileData?.professionalExperience
                        ?.professionalExperience_currentOrganization
                    }
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Total Experience</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {`${profileData?.professionalExperience?.professionalExperience_totalExperienceInYear} 
                    ${profileData?.professionalExperience?.professionalExperience_totalExperienceInYear > 1 ? `years` : `year`}
                    ${profileData?.professionalExperience?.professionalExperience_totalExperienceInMonth} 
                    ${profileData?.professionalExperience?.professionalExperience_totalExperienceInMonth > 1 ? `months` : `month`}
                    `}
                  </div>
                </div>
                <hr />

                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Skills</h6>
                  </div>
                  {/* <div className="col-sm-9 link-text-social">
                    {profileData?.professionalExperience?.professionalExperience_skills?.map(
                      (item) => (
                        <span key={item.name}>{item.name}</span>
                      )
                    )}
                  </div> */}

                  <div
                    className="col-sm-9 link-text-social d-flex  flex-wrap"
                    style={{ gap: "7px" }}
                  >

                    {
                      profileData?.professionalExperience?.professionalExperience_skills?.map((item) => (
                        <div className="asansol" key={item?.name}>{item?.name}</div>

                      ))
                    }
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Notice Period</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {` ${profileData?.professionalExperience?.professionalExperience_noticePeriodInMonth?.name} ${profileData?.professionalExperience?.professionalExperience_noticePeriodInMonth?.name > 1 ? `months` : `month`}`}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">No. of Projects</h6>
                  </div>

                  <div className="col-sm-9 link-text-social">
                    {
                      profileData?.professionalExperience
                        ?.professionalExperience_projectCompleted
                    }
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Current Payout</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {`INR ${profileData?.professionalExperience?.professionalExperience_currentPayout} ${profileData?.professionalExperience?.professionalExperience_currentPayout >= 100000 ? `lpm` : `pm`}`}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Expected Payout</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {`INR ${profileData?.professionalExperience?.professionalExperience_expectedPayout} ${profileData?.professionalExperience?.professionalExperience_expectedPayout >= 100000 ? `lpm` : `pm`}`}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3 d-flex">
                    <h6 className="mb-0">Preferred Location</h6>
                  </div>
                  <div
                    className="col-sm-9 link-text-social d-flex"
                    style={{ gap: "7px" }}
                  >

                    {
                      profileData?.preferredLocation?.map((item) => (
                        <div className="asansol" key={item?.name}>{item?.name}</div>

                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3 mt-2">
              <div className="profile-card-body">
                <h4>Education Details</h4>
                <div className="row mt-3">
                  <div className="col-sm-3">
                    <h6 className="mb-0">College Name</h6>
                  </div>
                  <div className="col-sm-8 link-text-social">
                    {profileData?.education?.education_instituteName}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Qualification</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {profileData?.education?.education_qualification?.name}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Specialization</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {profileData?.education?.education_specializaion}{" "}
                  </div>
                </div>
                <hr />

                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Year of Passing</h6>
                  </div>
                  <div className="col-sm-9 link-text-social">
                    {new Date(
                      profileData?.education?.education_yearOfPassing?.name
                    )?.getFullYear()}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="profile-details-section col-sm-6 mb-3 d-flex">
              <div className="col-sm-12 mb-3">
                <div className="col-md-12">
              <div className="profile-card mb-3">
              <div className="profile-card-body">
              <h5>Professional Experience</h5>
                <div className="row mt-5">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">Current Designation Role</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    React Developer
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">Current Organization Name</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    Cariva Technologies
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">Total Experience In year</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    3
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">Skills</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    React, HTML, DevOps
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">No. of Projects Completed</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    4
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">Current Payout in month</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    40000
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">Expected Payout in month</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    60000
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
            </div>
          <div className="col-md-12">
            <div className="profile-card mb-3">
              <div className="profile-card-body">
                <h5>Education Details</h5>
                <div className="row mt-5">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">University/College Name</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    Asansol Engineering College
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">Qualification</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    B.Tech
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">Specialization</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    Computer Science Engineering
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-5 col-sm-3">
                    <h6 className="mb-0">Year of Passing</h6>
                  </div>
                  <div className="col-md-7 col-sm-9 text-secondary">
                    2025
                  </div>
                </div>
                <hr />
              </div>
            </div>
            </div>
                {/* <div className="profile-card h-100">
                  <div className="profile-card-body">
                    <h6 className="d-flex align-items-center mb-3">Tech Status</h6>
                    <small>HTML5-80%</small>
                    <div className="progress mb-3" style={{ height: '5px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: '80%' }} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>DevOps-72%</small>
                    <div className="progress mb-3" style={{ height: '5px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: "72%" }} aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>.Net-89%</small>
                    <div className="progress mb-3" style={{ height: '5px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: '89%' }} aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>Android-55%</small>
                    <div className="progress mb-3" style={{ height: "5px" }}>
                      <div className="progress-bar" role="progressbar" style={{ width: "55%" }} aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>IOS-66%</small>
                    <div className="progress mb-3" style={{ height: '5px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: '66%' }} aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div> 
              </div> 
              {/* <div className="col-sm-6 mb-3">
                <div className="profile-card h-100">
                  <div className="profile-card-body">
                    <h6 className="d-flex align-items-center mb-3">Project Status</h6>
                    <small>Web Design</small>
                    <div className="progress mb-3" style={{ height: '5px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: '80%' }} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>Website Markup</small>
                    <div className="progress mb-3" style={{ height: '5px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: '72%' }} aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>One Page</small>
                    <div className="progress mb-3" style={{ height: '5px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: '89%' }} aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>Mobile Template</small>
                    <div className="progress mb-3" style={{ height: '5px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: '55%' }} aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small>Backend API</small>
                    <div className="progress mb-3" style={{ height: '5px' }}>
                      <div className="progress-bar" role="progressbar" style={{ width: '66%' }} aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProfileDev;
