import React, { useState } from 'react'
import styles from "./LandingNavbar.module.css"
import logo from '../../../../../assets/images/logo - Copy.png'
import { Link, NavLink } from "react-router-dom"
import fireicon from '../../../../../assets/images/fire-hot.png'
import { TbMenu2 } from "react-icons/tb";
import { MdClose } from "react-icons/md";
const LandingNavbar = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [colors, setColors] = useState(false)
    const changeColors = () => {
        if (window.scrollY >= 150) {
            setColors(true);
        } else {
            setColors(false);
        }
    }

    window.addEventListener('scroll', changeColors)

    return (
        <div className={`${styles.LandingNavbar} ${colors ? styles.increaseWidth : ""}`}   >
            <div className={styles.landingnavbarLeft}>
                <Link to="/">
                    <img src={logo} alt="Cariva logo" width={100} height={30}/>
                </Link>
            </div>
            <div className={styles.HamburgerMenu } onClick={() => setIsOpen(true)}  > <TbMenu2 size={"24px"} /></div>
            <div className={`${styles.landingnavbarRight} ${isOpen && styles.translateX100}`}>
            <div className={styles.Hamburgerclose } onClick={() => setIsOpen(false)}  > <MdClose  size={"24px"} /></div>
                <div className={styles.landingnavbarRightcont} >
                    <Link to='/partner' >Partners</Link>
                    {/* <Link to='/hot-job'>Hot Jobs <img src={fireicon} width={18} alt='Hot' /></Link> */}
                </div>
                <div className={styles.landingnavbarRightcont} >
                    <div className={styles.landingnavbarRightButton} style={{ gap: `${colors ? '10px' : "30px"}` }} >
                        <span className={colors ? styles.hightlight : ""} ><Link to='/hire-developer'> <span style={{ color: `${colors ? 'white' : "black"}` }} > Hire Talents</span></Link></span>
                        <span className={colors ? styles.hightlight : ""} ><Link to='/apply-job'> <span style={{ color: `${colors ? 'white' : "black"}` }} > Find Jobs</span></Link></span>
                    </div>
                    <span><Link to='/signin'>Login</Link></span>
                </div>
            </div>
        </div>
    )
}

export default LandingNavbar
