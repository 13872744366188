import React, { useState } from 'react'
import styles from "./PasswordReset.module.css"
import { useFormik } from "formik"
import * as Yup from "yup"
import {  useParams, useSearchParams } from "react-router-dom"
import axios from 'axios';
import { BServer } from "../../../../../server/backend"
import { useDispatch, useSelector } from "react-redux"
import InputBootstrap from '../../../childB/inputBootstrap/InputBootstrap'
import { globalModal } from '../../../../../Redux/actions/globalModal'
import { toast } from 'react-toastify'

export default function PasswordReset() {
  const dispatch = useDispatch()
  const {secretKeyFromParams}=useSelector((state)=>state?.globalModal?.data)
  const passwordRegex = /^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,22}$/;
   console.log('secretKeyFromParams',secretKeyFromParams)
  const { values, handleChange, handleSubmit,handleBlur, touched,resetForm, errors } = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,22}$/, 'Must be alpha numeric with special character')
        .required('New password required'),
      // .test('is-valid-username', 'Must contain lowercase, uppercase letter, one number, special character and 8 to 22 characters long', (value) => {
      //   return passwordRegex.test(value);
      // }),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], 'Confirm Password must match New Password')
        .required('Confirm new password required'),
    }),
    onSubmit: values => {
      
      axios.post(`${BServer}/resetPasswordUsingSecretKey/${secretKeyFromParams}`, {
        "newPassword": values.newPassword
      }).then(res => {
        console.log(res)
        if (res.status === 200) {
          setTimeout(() => {
                dispatch(
                  globalModal({
                    component: "ContactFormModal",
                    closeButton: true,
                    size: "md",
                    data:{message:res?.data?.data?.message}
                  })
                );
             
        }, 1000);

        setTimeout(() => {
          window.open('/signin')
        }, 2000);

        }else{
          toast.error(res?.data?.message)
        }
      }).catch(err => {
        console.log('error in reset password 60',err)
        if(err?.response?.status==400){
          toast.error(err?.response?.data?.message)
          dispatch(globalModal({type:'HIDE_MODAL'}))
          window.location.href = '/signin';   
                }

      })

    }
  });
  console.log(errors)
  const [isShow, setIsShow] = useState(false);
  const isShowHandler = () => {
    setIsShow(!isShow)
  }

  return (
    <div className={styles.PasswordReset} >
      <h1>Reset Password</h1>
      <form onSubmit={handleSubmit}>
        <InputBootstrap
          isPasswordFeild={true}
          label={'New Password'}
          id="pass"
          type={isShow ? 'text' : 'password'}
          placeholder={"********"}
          name={'newPassword'} value={values.newPassword}
          onChange={handleChange}
          errorClassName={'signup_error'}
          error={errors.password}
          onBlur={handleBlur}
          error={touched?.newPassword&& errors.newPassword}
        // type={!visible ? "password" : "text"}
        />
        <InputBootstrap
          isPasswordFeild={true}
          label={'Confirm new Password'}
          id="pass"
          type={isShow ? 'text' : 'password'}
          placeholder={"********"}
          name={'confirmPassword'} value={values.confirmPassword}
          onChange={handleChange}
          errorClassName={'signup_error'}
          error={touched?.confirmPassword&& errors.confirmPassword}
        // type={!visible ? "password" : "text"}
        />
        {/* <label htmlFor="">New password</label>
        <input type={`${isShow ? 'text' : 'password'}`} name='newPassword' value={values.newPassword} onChange={handleChange} />
        <span>{errors.newPassword}</span>
        <label htmlFor="">Confirm password</label>
        <input type={`${isShow ? 'text' : 'password'}`} name='confirmPassword' value={values.confirmPassword} onChange={handleChange} />
        <span>{errors.confirmPassword}</span>
        <div className={styles.isShow}>
          <input type="checkbox" name="isShow" onClick={isShowHandler} className='' />
          <small>Show password</small>
        </div> */}
        <button type='submit' style={{background:'#18042B'}}>Submit</button>
      </form>
    </div>
  )
}
