// for create developer profile

const initialValueDev={
    profilePicture:'',
    resume:'',
    fullName:'',
    dateOfBirth:'',
    gender:'',
    email:'',
    // mobile:'',
    currentLocation_country:{},
    currentLocation_state:{},
    currentLocation_district:{},
    currentLocation_city:{},
    currentLocation_pinCode:'',
    preferredLocation:[],
    education_instituteName:'',
    education_qualification:'',
    education_specializaion:'',
    education_yearOfPassing:'',
    professionalExperience_currentDesignation:'',
    professionalExperience_currentOrganization:'',
    professionalExperience_totalExperienceInYear:0,
    professionalExperience_totalExperienceInMonth:0,
    professionalExperience_noticePeriodInMonth:'',
    professionalExperience_skills:[],
    professionalExperience_projectCompleted:'',
    professionalExperience_currentPayout:'',
    professionalExperience_expectedPayout:'',
    social_linkedin:'',
    social_git:'',

}
const nameValueDev={
    profilePicture:'profilePicture',
    resume:'resume',
    fullName:'fullName',
    dateOfBirth:'dateOfBirth',
    gender:'gender',
    email:'email',
    mobile:'mobile',
    currentLocation_country:'currentLocation_country',
    currentLocation_state:'currentLocation_state',
    currentLocation_district:'currentLocation_district',
    currentLocation_city:'currentLocation_city',
    currentLocation_pinCode:'currentLocation_pinCode',
    preferredLocation:'preferredLocation',
    education_instituteName:'education_instituteName',
    education_qualification:'education_qualification',
    education_specializaion:'education_specializaion',
    education_yearOfPassing:'education_yearOfPassing',
    professionalExperience_currentDesignation:'professionalExperience_currentDesignation',
    professionalExperience_currentOrganization:'professionalExperience_currentOrganization', 
    professionalExperience_totalExperienceInYear:'professionalExperience_totalExperienceInYear',
    professionalExperience_totalExperienceInMonth:'professionalExperience_totalExperienceInMonth',
    professionalExperience_noticePeriodInMonth:'professionalExperience_noticePeriodInMonth',
    professionalExperience_skills:'professionalExperience_skills',
    professionalExperience_projectCompleted:'professionalExperience_projectCompleted',
    professionalExperience_currentPayout:'professionalExperience_currentPayout',
    professionalExperience_expectedPayout:'professionalExperience_expectedPayout',
    social_linkedin:'social_linkedin',
    social_git:'social_git',

}


 const initialValueAgency={

    profilePicture:'',
    fullName:'',
    description:'',
    email:'',

    overview_areaOfExpertise:[],
    overview_companySize:{},
    overview_industrialExperience:0,

    location_address:'',
    location_country:{},
    location_state:{},
    location_district:{},
    location_city:{},
    location_pinCode:'',

    social_linkedin:'',
    social_website:'',

    documents_registration:'',
    documents_panCard:'',
    documents_gstCertificate:'',
    otherDocuments: [],
    assignVerificationTo:{}

}
 const nameValueAgency={
    profilePicture:'profilePicture',
    fullName:'fullName',
    description:'description',
    email:'email',
    overview_areaOfExpertise:'overview_areaOfExpertise',
    overview_companySize:'overview_companySize',
    overview_industrialExperience:'overview_industrialExperience',
    
    location_address:'location_address',
    location_country:'location_country',
    location_state:'location_state',
    location_district:'location_district',
    location_city:'location_city',
    location_pinCode:'location_pinCode',

    social_linkedin:'social_linkedin',
    social_website:'social_website',

    documents_registration:'documents_registration',
    documents_panCard:'documents_panCard',
    documents_gstCertificate:'documents_gstCertificate',
    otherDocuments: 'otherDocuments',

    assignVerificationTo:'assignVerificationTo'


}



const initialValueAdmin={
    profilePicture:'',
    fullName:'',
    dateOfBirth:'',
    gender:'',

    currentLocation_address:'',
    currentLocation_country:{},
    currentLocation_state:{},
    currentLocation_district:{},
    currentLocation_city:{},
    currentLocation_pinCode:'',

    professionalExperience_currentDesignation:'',
  
    social_linkedin:'',

}
 const nameValueAdmin={
    profilePicture:'profilePicture',
    fullName:'fullName',
    dateOfBirth:'dateOfBirth',
    gender:'gender',

    currentLocation_address:'currentLocation_address',
    currentLocation_country:'currentLocation_country',
    currentLocation_state:'currentLocation_state',
    currentLocation_district:'currentLocation_district',
    currentLocation_city:'currentLocation_city',
    currentLocation_pinCode:'currentLocation_pinCode',

    professionalExperience_currentDesignation:'professionalExperience_currentDesignation',
   
    social_linkedin:'social_linkedin',
    

}




export {initialValueDev,nameValueDev,initialValueAgency,nameValueAgency,initialValueAdmin,nameValueAdmin}