import *as Yup from 'yup'


export const signupValidation = (userRole) => {
    if (userRole === 'agency') {
        return Yup.object({
            email: Yup
                .string()
                .email('Email is invalid')
                .test('is-not-free-email', 'Please register with valid work email.', function (value) {
                    // List of free email domains to exclude
                    const excludeDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'protonmail.com', 'zoho.com', 'mail.com', 'gmx.com', 'aol.com', 'yandex.com', 'tutanota.com'];

                    // Extract the domain from the email address
                    const domain = (value || '').split('@')[1];

                    // Check if the domain is not in the excluded list

                    // it should !excludeDomains
                    return !excludeDomains.includes(domain);


                })
                .test('Email is invalid', (value) => {
                    return value.split('@')[1]?.includes('.')
                })
                .required('Email is required'),
        })
    } else if (userRole === 'developer') {
        return Yup.object({
            email: Yup
                .string()
                .email('Email is invalid')
                .required('Email is required')
                .test('Email is invalid', (value) => {
                    return value.split('@')[1]?.includes('.')
                }),
        })
    }
}

// ([/@gmail\.com/, /yahoo\.com/, null])


