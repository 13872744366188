import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import './SignupPage.css'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { userRoles } from '../../../assets/users/userRoles';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { signupAction } from '../../../Redux/actions/signupAction';
import { useFormik } from 'formik';
import InputBootstrap from '../../../components/common/childB/inputBootstrap/InputBootstrap';
import { signupValidation } from '../../../assets/yupValidation/signupValidation';
import { useNavigate } from 'react-router-dom';

const SignupPage = () => {
  const [userRole, setUserRole] = useState(userRoles[0].userRole)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { values, handleChange, handleSubmit, resetForm, errors } = useFormik({
    initialValues: {
      email: ''
    },

    validationSchema: signupValidation(userRole),
    onSubmit: async (values, resetForm) => {
      // console.log('success', values);

      dispatch(signupAction(values.email?.toLowerCase(), userRole, () => navigate('/signin', { state: { message: 'Please check your email for the login credential.' } })))

    },
  });


  const handleRegister = (e) => {
    e.preventDefault()
    if (errors.email) {
      toast.error(errors.email)
    } else {
      handleSubmit();

    }
  };


  const handleUserRole = (arg) => {
    setUserRole(arg)
    resetForm()

  }




  return (

    <>

      <div className="row col-12 register m-0 p-0">

        <div className="col-md-5 register-left">

          <h3>Welcome</h3>
          <p>Build Your Tech Team Now!</p>
          {/* <a href=''>
            <input type="submit" name="" value="Login" /><br />
            </a> */}
          <Button variant="" className='col-8 register-left-login'
          onClick={()=>navigate('/signin')}
          >Login</Button>
        </div>
        
        <div className="col-md-7 register-right">

          <ButtonGroup aria-label="Basic example" className='nav-tabs'>
            {
              userRoles.map((item, index) => (
                index < 2 && (
                  <Button variant="" className={`nav-role 
                  ${userRole !== item.userRole && 'nav-role-active'}
                  `
                } key={item.roleId} onClick={() => handleUserRole(item.userRole)}>{item.userRole}</Button>
                )

              ))
            }

          </ButtonGroup>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <h3 className="register-heading">Sign up as {userRole}</h3>
              <div className="row register-form d-flex align-items-center justify-content-center ">

                <div className="col-md-6">
                  <Form>


                    {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control type="text" placeholder="name@example.com" className='' name='email' value={values.email} onChange={handleChange} isInvalid={errors.email ? true : false}  />

                      <span className='signup_error'>{errors.email}</span>
                    </Form.Group> */}

                    <InputBootstrap label={'Email address'} type={'text'} placeholder={"name@example.com"} name={'email'} value={values.email} onChange={handleChange} isInvalid={errors.email ? true : false} errorClassName={'signup_error'} error={errors.email} className={'signname'} />


                  </Form>
                  <div className='d-flex justify-content-center'>
                    <Button
                      variant="primary"
                      className='btnRegister'
                      // disabled={isLoading}
                      onClick={handleRegister}
                    >
                      Register
                    </Button>
                  </div>


                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </>


  )
}

export default SignupPage




{/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control type="password" placeholder="Confirm Password" />
                    </Form.Group> */}