import React from 'react'
import styles from "./CreateProfileSubmission.module.css"
import {useDispatch} from "react-redux"
import { IoMdClose } from 'react-icons/io'

export default function CreateProfileAdminSubmission() {
  const dispatch = useDispatch()

  return (
      <div className={`${styles.SubmissionModal} ${styles.SpecailForResponsive}`} >
        <h3>Confirmation</h3>
        <p>
          Do you want to submit the details?
        </p>
        <div className={styles.SubmissionModalActions}>
          <button onClick={() => dispatch({ type: 'HIDE_MODAL' })} >Cancel</button>
          <button>Submit</button>
        </div>
      </div>
  )
}
