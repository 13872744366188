import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const WithoutLogin = () => {
    const authentication = useSelector((state) => state?.authentication)
    return (
        <>
            {
                !authentication.token ?

                    <Outlet />

                    :
                    <Navigate to={authentication?.userRole === 'sAdmin' ? `/admin` : `${authentication?.userRole}`} />

            }
        </>
    )
}

export default WithoutLogin