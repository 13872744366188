import React, { useState, useEffect } from 'react'
import styles from "./EmailVerification.module.css"
import { BiLoaderAlt } from "react-icons/bi";
import { MdDone } from "react-icons/md";
import { globalModal } from '../../../Redux/actions/globalModal';
import { useDispatch } from "react-redux"
import axios from 'axios';
import { BServer } from '../../../server/backend';
import { useParams } from "react-router-dom"

export default function EmailVerification() {
  const dispatch = useDispatch()
  const [responceObject, setresponceObject] = useState({})
  const { secretKeyFromParams } = useParams();
console.log('secretKeyFromParams 15',secretKeyFromParams)
  useEffect(() => {
    axios.get(`${BServer}/getEmailAndMemberId/${secretKeyFromParams}`).then(res => {
      console.log(res)
      console.log(res.data.data.document)
      setresponceObject({
        "email": res.data.data.document.email,
        "memberId": res.data.data.document.memberId,
        "expiredAt": res.data.data.document.expiredAt,
        "userId": res.data.data.document.userId

      })
    }).catch(err => {
      console.log(err)
    })
  }, [BServer]);

  const [isLoading, setisLoading] = useState(false)
  const [buttonText, setbuttonText] = useState('Verify Email')
  const [verifyed, setisverifyed] = useState(false)

  const VerifyEmailHandler = () => {
    setisverifyed(false)
    setisLoading(true)
    setbuttonText("Verifying...")
    console.log(responceObject)
    axios.post(`${BServer}/verifySecretKey/${secretKeyFromParams}`, responceObject).then(res => {
      console.log(res)
      if (res.data.data.document.verifiedSecretKey) {
        setisLoading(false)
        setbuttonText("Verified")
        setisverifyed(true)
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const resetPasswordHandler = () => {
    dispatch(globalModal({ component: 'PasswordReset', closeButton: true ,data:{secretKeyFromParams} }))
  }

  return (
    <div className={styles.EmailVerification}>
      <div className={styles.emailverificationcard}>
        <h1>Verify Email Address</h1>
        <div className={styles.detail}>
          <p>Email Address : <span>{responceObject.email}</span></p>
          <p>Member ID : <span>{responceObject.memberId}</span></p>
        </div>
        <button onClick={VerifyEmailHandler} disabled={verifyed} style={{cursor:verifyed&&'not-allowed'}}>
          {isLoading && <BiLoaderAlt className={styles.loaderEmail} size={"20px"} />}
          {verifyed && <MdDone size={"20px"} />}
          {buttonText}
        </button>
        {verifyed &&
          <button onClick={resetPasswordHandler} >Reset Password</button>
        }
      </div>
    </div>
  )
}
