import React from 'react'
import styles from './NavbarDropdown.module.css'
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';


const Dropdown = ({ handleCloseOffcanvas, expand, title, items, }) => {
    const { userRole } = useSelector((state) => state?.authentication)
    return (
        <NavDropdown
            title={title}
            id={`offcanvasNavbarDropdown-expand-${expand}`}
            className={styles.NavDropDown}
        >

            {
                items.map((item) => (item?.allowedRole ? (
                    item?.allowedRole?.includes(userRole) &&
                    <NavDropdown.Item as='div' key={item.title} onClick={handleCloseOffcanvas}>
                        <Link
                            // to={`${userRole!=='sAdmin'?userRole:'admin'}/${item.route}`} 
                            to={`${item.route}`}

                            className={styles.itemsdrop}>{item.title}</Link>
                    </NavDropdown.Item>
                )
                    :
                    <NavDropdown.Item as='div' key={item.title} onClick={handleCloseOffcanvas}>
                        <Link
                            // to={`${userRole!=='sAdmin'?userRole:'admin'}/${item.route}`} 
                            to={`${item.route}`}

                            className={styles.itemsdrop}>{item.title}</Link>
                    </NavDropdown.Item>


                ))
            }

        </NavDropdown>
    )
}

export default Dropdown