function formatLocalizedDateDifference(localizedDateString) {
    // Convert input date string to Date object
    const date = new Date(localizedDateString);
    // Get current date
    const currentDate = new Date();
  
    // Calculate the difference in milliseconds
    const timeDifference = currentDate - date;
  
    // Calculate the difference in days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
    // Check the difference and return the appropriate string
    if (daysDifference === 0) {
      return 'Today';
    } else if (daysDifference === 1) {
      return 'Yesterday';
    } else if (daysDifference >= 30) {
      const monthsDifference = Math.floor(daysDifference / 30);
      return `${monthsDifference} month${monthsDifference > 1 ? 's' : ''} ago`;
    } else {
      return `${daysDifference} days ago`;
    }
  }
  
  // Example usage:
  const dateString = new Date('8/2/2022').toLocaleDateString(); // Replace this with your date string
  const result = formatLocalizedDateDifference(dateString);
  console.log(result);
  module.exports={formatLocalizedDateDifference}