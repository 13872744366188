// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SwitchButtons_switch_btn__AOp1\\+{
    justify-content: center;
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/common/childA/switchbuttons/SwitchButtons.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;AACjB","sourcesContent":[".switch_btn{\n    justify-content: center;\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch_btn": `SwitchButtons_switch_btn__AOp1+`
};
export default ___CSS_LOADER_EXPORT___;
