
import React from 'react'
import styles from "./ViewClient.module.css"
import { useSelector } from 'react-redux'

const ViewClient = () => {
  const data=useSelector((state)=>state?.globalModal?.data)
  return (
    <div className={styles.ViewClient} >
      <h1>Requirement Details</h1>
      <p> <span> Name</span> :&nbsp;{data?.firstName} {data?.lastName} </p>
      <p> <span> Email Address</span> :&nbsp;{data?.email}</p>
      <p> <span> Phone Number</span> :&nbsp;+91-{data?.mobile}</p>
      <p> <span> Company Name</span> :&nbsp;{data?.companyName}</p>
      <p> <span> Core Stacks</span> :&nbsp;{data?.domaiOfExpertise?.map((i)=>
        `${i.name}, `
      )}
      </p>
      <p> <span> Requirement</span>:&nbsp; <section>{data?.description}</section> </p>
    </div>
  )
}

export default ViewClient