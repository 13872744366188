
export const flattenedDocuments = props => props.map(doc => flattenObject(doc));


function flattenObject(obj, prefix = '') {
    let result = {};
    for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            const nestedObj = flattenObject(obj[key], `${prefix}${key}.`);
            result = { ...result, ...nestedObj };
        } else {
            result[`${prefix}${key}`] = obj[key];
        }
    }
    return result;
}