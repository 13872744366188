// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyTable_container__YGfSo{
  /* margin-top: 1%;
  margin-left: 5%;
  margin-right: 5%; */
  margin: 3%;
  overflow: scroll;
}
.MyTable_custom_table__dA7fF {
    border-collapse: collapse;
    width: 100%;
    border: solid 1px #c0c0c0;
    font-family: 'poppins';
    font-size: 15px;
  }
  
  .MyTable_custom_table__dA7fF th,
  .MyTable_custom_table__dA7fF td {
    text-align: center;
    padding: 8px;
    border: solid 1px #e3e3e3;
    font-size: 13px;
    cursor: pointer;
  }
  
  .MyTable_custom_table__dA7fF th {
    color: #fff;
    font-weight: 300;
  }
  
  /* .custom_table tr:nth-child(odd) {
    background-color: #ffffff;
  } */
  
  thead{
    background-color: #18042B !important;
  }
 
  
  .MyTable_tbtn__OvjtT {
    border: 0;
    outline: 0;
    background-color: transparent;
    font-size: 13px;
    cursor: pointer
  }
  
  .MyTable_toggler__hkyMr {
    display: none
  }
  
  .MyTable_toggler1__9LtAa {
    display: table-row;
  }
  
  .MyTable_custom_table__dA7fF a {
    color: #0033cc;
  }
  
  .MyTable_custom_table__dA7fF a:hover {
    color: #f00;
  }
  
  .MyTable_page_header__QDRUG {
    background-color: #eee;
  }
  .MyTable_resume_sec__9auqk{
    width: 13%;
  }
  .MyTable_resume_sec__9auqk img{
    width: 10%;
    
  }`, "",{"version":3,"sources":["webpack://./src/components/common/childA/table/MyTable.module.css"],"names":[],"mappings":"AAAA;EACE;;qBAEmB;EACnB,UAAU;EACV,gBAAgB;AAClB;AACA;IACI,yBAAyB;IACzB,WAAW;IACX,yBAAyB;IACzB,sBAAsB;IACtB,eAAe;EACjB;;EAEA;;IAEE,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,gBAAgB;EAClB;;EAEA;;KAEG;;EAEH;IACE,oCAAoC;EACtC;;;EAGA;IACE,SAAS;IACT,UAAU;IACV,6BAA6B;IAC7B,eAAe;IACf;EACF;;EAEA;IACE;EACF;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,sBAAsB;EACxB;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;;EAEZ","sourcesContent":[".container{\n  /* margin-top: 1%;\n  margin-left: 5%;\n  margin-right: 5%; */\n  margin: 3%;\n  overflow: scroll;\n}\n.custom_table {\n    border-collapse: collapse;\n    width: 100%;\n    border: solid 1px #c0c0c0;\n    font-family: 'poppins';\n    font-size: 15px;\n  }\n  \n  .custom_table th,\n  .custom_table td {\n    text-align: center;\n    padding: 8px;\n    border: solid 1px #e3e3e3;\n    font-size: 13px;\n    cursor: pointer;\n  }\n  \n  .custom_table th {\n    color: #fff;\n    font-weight: 300;\n  }\n  \n  /* .custom_table tr:nth-child(odd) {\n    background-color: #ffffff;\n  } */\n  \n  thead{\n    background-color: #18042B !important;\n  }\n \n  \n  .tbtn {\n    border: 0;\n    outline: 0;\n    background-color: transparent;\n    font-size: 13px;\n    cursor: pointer\n  }\n  \n  .toggler {\n    display: none\n  }\n  \n  .toggler1 {\n    display: table-row;\n  }\n  \n  .custom_table a {\n    color: #0033cc;\n  }\n  \n  .custom_table a:hover {\n    color: #f00;\n  }\n  \n  .page_header {\n    background-color: #eee;\n  }\n  .resume_sec{\n    width: 13%;\n  }\n  .resume_sec img{\n    width: 10%;\n    \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MyTable_container__YGfSo`,
	"custom_table": `MyTable_custom_table__dA7fF`,
	"tbtn": `MyTable_tbtn__OvjtT`,
	"toggler": `MyTable_toggler__hkyMr`,
	"toggler1": `MyTable_toggler1__9LtAa`,
	"page_header": `MyTable_page_header__QDRUG`,
	"resume_sec": `MyTable_resume_sec__9auqk`
};
export default ___CSS_LOADER_EXPORT___;
