// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GlobalModal_ModalBackdrop__9mk\\+Y {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.392);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999999999;
    display: grid;
    place-items: center;
}
.GlobalModal_ModalContent__5kmtG {
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/globalModal/GlobalModal.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sCAAsC;IACtC,kCAA0B;YAA1B,0BAA0B;IAC1B,eAAe;IACf,MAAM;IACN,OAAO;IACP,sBAAsB;IACtB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".ModalBackdrop {\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.392);\n    backdrop-filter: blur(2px);\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 9999999999999;\n    display: grid;\n    place-items: center;\n}\n.ModalContent {\n    width: fit-content;\n    height: fit-content;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModalBackdrop": `GlobalModal_ModalBackdrop__9mk+Y`,
	"ModalContent": `GlobalModal_ModalContent__5kmtG`
};
export default ___CSS_LOADER_EXPORT___;
