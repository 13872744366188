import React from "react";
import Spinner from 'react-bootstrap/Spinner';
import favicon11 from "../../../../assets/images/favicon.png";
import styles from "./Loader.module.css";
const Loader = () => {
  return (
    <div className={styles.loader_main}>
      {/* <div className={styles.loader_container}>
            <img className={styles.imgBig} src={favicon11} />
            {/* <div className={styles.ring}></div>
            <div className={styles.ring}></div>
            <div className={styles.ring}></div> 
  </div> */}
      {/* <Spinner animation="border" role="status" className={styles.lodrr}> */}
      {/* <span className="visually-hidden">Loading...</span> */}
      {/* <img className={styles.imgBig} src={favicon11} /> */}
      {/* </Spinner> */}
      <div className={styles.loader_main_one}>
        <img className={styles.imgBig} src={favicon11} />
        <div className={styles.loader}></div>
      </div>

    </div>


  );
};

export default Loader;
