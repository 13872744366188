export const navItems = [
    { id: 1, title: 'Partners', protected: false, route: '/about', type: 'button', allowedRole: ['admin', 'sAdmin', 'agency', 'developer'] },

    { id: 2, title: 'Hot Jobs', protected: false, route: '/services', type: 'button', allowedRole: ['admin', 'sAdmin', 'agency', 'developer'] },

    { id: 3, title: 'Career', protected: false, route: '/hire-developers', type: 'button', allowedRole: ['admin', 'sAdmin', 'agency', 'developer'] },

    { id: 4, title: 'Hire Developer', protected: false, route: '/insights', type: 'button', allowedRole: ['admin', 'sAdmin', 'agency', 'developer'] },

    { id: 5, title: 'Apply for Job', protected: false, route: '/careers', type: 'button', allowedRole: ['admin', 'sAdmin', 'agency', 'developer'] },




    { id: 8, title: 'My Profile', protected: true, route: '', type: 'dropdown', allowedRole: ['admin', 'sAdmin', 'agency', 'developer'], dropdownItem: [{ title: 'View Profile', route: 'profile/view' }, { title: 'Edit Profile', route: 'profile/edit' }] },

    { id: 9, title: 'Job', protected: true, route: '', type: 'dropdown', allowedRole: ['admin', 'sAdmin', 'agency', 'developer'], dropdownItem: [{ title: 'All job', route: 'job',allowedRole: ['admin', 'sAdmin','agency', 'developer'], }, { title: 'Applied job', route: 'job/applied', allowedRole: ['agency', 'developer'], }, { title: 'Create Job', route: 'job/create',allowedRole: ['admin', 'sAdmin'], }] },





    { id: 10, title: 'Developer', protected: true, route: '', type: 'dropdown', allowedRole: ['agency', 'admin', 'sAdmin'], dropdownItem: [{ title: 'My Developer', route: 'my-developer' }, { title: 'Add Developer', route: 'my-developer/create' }] },

    { id: 11, title: 'Candidate', protected: true, route: 'candidate', type: 'button', allowedRole: ['admin', 'sAdmin'] },

    { id: 12, title: 'Agency', protected: true, route: 'agencies', type: 'button', allowedRole: ['admin', 'sAdmin'] },
    { id: 13, title: 'Client', protected: true, route: 'client', type: 'button', allowedRole: ['admin', 'sAdmin'] },
    { id: 14, title: 'Admin', protected: true, route: 'my-admin', type: 'button', allowedRole: ['sAdmin'], },








    { id: 6, title: 'Login', protected: false, route: '/signin', type: 'button', allowedRole: ['admin', 'sAdmin', 'agency', 'developer'] },

    { id: 7, title: 'Logout', protected: true, route: '', type: 'button', allowedRole: ['admin', 'sAdmin', 'agency', 'developer'] },





]