import React, { useEffect, useState } from 'react'
import styles from "./MyAdmins.module.css"
import stylesTable from "../../../components/common/childA/table/MyTable.module.css"

import MyTable from '../../../components/common/childA/table/MyTable'
import { fakeDataAdmins } from '../../../components/common/childA/usersapi/UserApis'
import { globalModal } from '../../../Redux/actions/globalModal'
import { useDispatch, useSelector } from "react-redux"
import { getClientListAction } from '../../../Redux/actions/hireDeveloper/getClientListAction'
import MyPagination from '../../../components/common/childA/pagination/MyPagination'
import { BServer } from '../../../server/backend'
import noprofileFound from "../../../assets/images/no-result-found.png"
const ViewClientList = () => {
    const dispatch = useDispatch()
    const [searchInput, setSearchInput] = useState('')
    const allClient = useSelector((state) => state?.clientListReducer)
    const TableHead = ["SN", "First Name", "Last Name", "Email Address", "Contact Number", "Company Name", "Requested On"]
    const inputOnChangeHandle = (e) => {
        setSearchInput(e.target.value)
    }
    console.log('allClient', allClient)
    const callbackFunction = (val) => {
        dispatch(globalModal({ component: 'ViewClient', closeButton: true, size: 'lg' }))
    }
    useEffect(() => {
        dispatch(getClientListAction(searchInput, 1, 10))
    }, [searchInput])
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of items per page
    const totalItems = allClient?.length;
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const tableData = allClient?.documents?.slice(firstIndex, lastIndex)
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    return (
        <div>
            <div>
                <section className={styles.banner}>
                    <div className="container-fluid ">
                        <div className="row align-items-center justify-content-center">
                            <div className={`${styles.head_st} ${styles.banner} `}>
                                <h2>View Client Lists</h2>
                                <div className={styles.searchbar}>
                                    <input type="text" placeholder="Enter first name or last name or company name or email" name='searchInput' value={searchInput} onChange={inputOnChangeHandle} />

                                    <button>Search</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <div className={stylesTable.container}>
                    {(tableData && tableData.length > 0) ? (<table responsive="sm" className={`${stylesTable.custom_table} mt-5 mb-5`}>
                        <thead>
                            <tr>
                                {TableHead?.map(val => {
                                    return (
                                        <th key={val}>{val}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.map((i, idx) => {
                                return (
                                    // <MyTableRow key={user?.memberId} userData={user} tableBody={tableBody} click={props.click} clickFunc={props.clickFunc} />
                                    <tr onClick={() => dispatch(globalModal({ component: 'ViewClient', closeButton: true, size: 'lg', data: i }))}>
                                        <td><span>{idx + 1}</span></td>
                                        <td><span>
                                            {i?.firstName}
                                        </span></td>
                                        <td><span>
                                            {i?.lastName}
                                        </span></td>
                                        <td><span>
                                            {i?.email}
                                        </span></td>
                                        <td><span>
                                            {i?.mobile}
                                        </span></td>
                                        <td><span>
                                            {i?.companyName}
                                        </span></td>

                                        <td>
                                            <span>
                                                {new Date(i?.createdAt)?.toDateString()}
                                            </span>
                                        </td>


                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>) : (<div className={styles.noProfilFound}>
                        <img className={styles.noprofileFoundImage} src={noprofileFound} alt="Md Mudassar" />
                        <p >No Client Found</p>
                    </div>)}

                </div>
                            
                           
                {tableData?.length > 0 && (< div className='a d-flex justify-content-center mt-5 mb-5' >

                    <MyPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />

                </div>)}

            </div>
        </div>
    )
}

export default ViewClientList
