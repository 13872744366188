import React, { memo } from "react";
import './CreateProfileDev.module.css'
import { useField } from "formik"
import styles from './CreateProfileDev.module.css'
const ProfilePageInputfeild = (props) => {
    const { label, required, name, type, disabled, error, allValue, value, edit } = props



    return (
        <div className={styles.ProfilePageInputfield}>
            <label htmlFor={label}>
                {label}
                {
                    required && <span> *</span>
                }
            </label>
            {/* <p>{edit &&value&&value}</p> */}
            <input
                {...props}
                placeholder={"Enter " + label}
                id={label}
                className={error && styles.errorRedBorder}
            />
            {
                error &&
                (
                    <p className={styles.ProfilePageInputfield_error_msg}>
                        {error}
                    </p>
                )
            }

        </div>
    )
}

export default memo(ProfilePageInputfeild)