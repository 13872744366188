import React, { useEffect, useState } from "react";
import styles from "./JobPostForm.module.css"
import ProfilePageInputfeild from "../../../forDeveloper/profilePage/createProfile/ProfilePageInputfeild";
import ProfilePageDropdownFeild from "../../../forDeveloper/profilePage/createProfile/ProfilePageDropdownFeild";
import CascadingDropdown from "../../../forDeveloper/profilePage/createProfile/CascadingDropdown";
import LocationDropdown from "../../../forDeveloper/profilePage/createProfile/LocationDropdown";
import { useDispatch, useSelector } from "react-redux"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { locationAction } from "../../../../Redux/actions/locationAction";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { initialValuejobPost, nameValueJobPost } from '../../../../assets/initialValues/jobPost'
import { createJobAction } from "../../../../Redux/actions/job/createJobAction";
import { singleJobAction } from "../../../../Redux/actions/job/singleJobAction";
import { jobPostValidation } from "../../../../assets/yupValidation/job/jobPostValidation";
import { Form } from "react-bootstrap";
const JobPostForm = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const fromUseLocation = useLocation()
    const params = useParams()

    const authentication = useSelector((state) => state?.authentication)
    const location = useSelector((state) => state?.location)
    const singleJob = useSelector((state) => state?.singleJob?.job)
    const SkillsOptions = useSelector((state) => state?.formDropdown?.techStacks)
    const DomaiOfExpertiseOptions = useSelector((state) => state?.formDropdown?.domainOfExpertise)

    const editOne = {
        // basic
        title: singleJob?.basic?.title,
        description: singleJob?.basic?.description,
        openingType: singleJob?.basic?.openingType,
        openings: singleJob?.basic?.openings,

        // profession
        domaiOfExpertise: singleJob?.profession?.domaiOfExpertise,
        skills: singleJob?.profession?.skills,
        totalExperienceInYear: singleJob?.profession?.totalExperienceInYear,

        // amount
        rate: singleJob?.amount?.rate,
        min: singleJob?.amount?.min,
        max: singleJob?.amount?.max,

        // interval
        start: singleJob?.interval?.start,
        end: singleJob?.interval?.end,

        // location
        location_country: singleJob?.location?.location_country,
        location_state: singleJob?.location?.location_state,
        location_district: singleJob?.location?.location_district,
        location_city: singleJob?.location?.location_city,

        // pointOfContact
        emailTo: singleJob?.pointOfContact?.emailTo,
        mobileTo: singleJob?.pointOfContact?.mobileTo,

        //  others
        jobType: singleJob?.others?.jobType,
        shiftType: singleJob?.others?.shiftType,
        visibility: singleJob?.others?.visibility,
        benifits: singleJob?.others?.benifits,
        makeItHot: singleJob?.others?.makeItHot
    }

    const { values, handleChange, handleSubmit, resetForm, errors, setFieldValue, handleBlur, touched } = useFormik({
        initialValues: params?.edit ? editOne : { ...initialValuejobPost, emailTo: authentication?.userEmail }
        ,
        validationSchema: jobPostValidation,
        enableReinitialize: true,

        // _idFromUseLocation will only be provided with dispatch when admin or sAdmin try to edit the job
        onSubmit: (values) => {

            dispatch(createJobAction(values, params?.edit === 'edit' ? 'EDIT' : 'CREATE',
                (prop) => {
                    console.log('prop to navigate',prop)
                    navigate(prop)
                }
                ,
                fromUseLocation?.state?._id, fromUseLocation?.state?.jobId
            ))

        }
    })
    console.log('error', errors)
    console.log('values', values)
    console.log('authentication?.email', authentication)

    useEffect(() => {
        if (params?.edit === 'edit') {
            dispatch(singleJobAction(fromUseLocation?.state?._id, fromUseLocation?.state?.jobId))
        }
    }, [])


    useEffect(() => {
        dispatch(locationAction('COUNTRY'))
    }, [values.location_country])
    useEffect(() => {
        if (values.location_country) {
            dispatch(locationAction('STATE', values.location_country?.geonameId))
        }
    }, [values.location_country])

    useEffect(() => {
        if (values.location_country) {
            dispatch(locationAction('DISTRICT', values.location_state?.geonameId))
        }
    }, [values.location_state])
    useEffect(() => {
        if (values.location_country) {
            dispatch(locationAction('CITY', values.location_district?.geonameId))
        }
    }, [values.location_district])



    return (
        <div className={styles.JobListingForm}>
            <h3>Job Form Details</h3>

            <div className={styles.DISPLAYFLEX}>
                <ProfilePageInputfeild label="Title" type="text" required={true} name={nameValueJobPost.title} value={values.title} onChange={handleChange} onBlur={handleBlur} error={touched.title && errors.title} />

                <LocationDropdown label="Opening Type" values={[{ name: "C2C", _id: "c2c" }, { name: "Full time", _id: "fullTime" }, { name: "C2H", _id: "c2h" },{ name: "Permanent or C2C", _id: "n permanentOrC2C" }]} required={true}
                    name={nameValueJobPost.openingType} value={values.openingType
                    } setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.openingType && errors.openingType}
                    previousValue={singleJob?.basic?.openingType}
                />
            </div>

            <div className={styles.DISPLAYFLEX}>
                <ProfilePageInputfeild label="Number Of Opening" type="number" name={nameValueJobPost.openings} value={values.openings} onChange={handleChange} onBlur={handleBlur} error={touched.openings && errors.openings} min={'1'}
                />

                <LocationDropdown label="Domain Of Expertise" values={DomaiOfExpertiseOptions}
                    required={true}
                    name={nameValueJobPost.domaiOfExpertise} value={values.domaiOfExpertise
                    } setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.domaiOfExpertise && errors.domaiOfExpertise}
                    previousValue={singleJob?.profession?.domaiOfExpertise}

                />
            </div>

            <div className={styles.DISPLAYFLEX}>
                <CascadingDropdown label={'Skills'} options={SkillsOptions} required={true} setFieldValue={setFieldValue} name={nameValueJobPost.skills} onBlur={handleBlur} error={touched.skills && errors.skills} value={values?.skills} />

                <LocationDropdown
                    label="Experience"
                    values={[
                        // { _id: 'fresher', name: 0 },
                         ...(Array.from({ length: 15 }, (_, i) => ({ _id: `year${i + 1}`, name: i + 1 }))), { _id: 'year15Plus', name: 16 }]}

                    required={true}
                    setFieldValue={setFieldValue}
                    onBlur={handleBlur}
                    name={nameValueJobPost.totalExperienceInYear} value={values.totalExperienceInYear}
                    error={touched.totalExperienceInYear && errors.totalExperienceInYear}
                    previousValue={singleJob?.profession?.totalExperienceInYear}
                    additionalValue={'+'}
                />
            </div>

            <div className={styles.DISPLAYFLEX}>
                <LocationDropdown label="Job Type" values={[{ name: "Hybrid", _id: "hybrid" }, { name: "Remote", _id: "remote" }, { name: "Onsite", _id: "onsite" }]} required={true}
                    setFieldValue={setFieldValue}
                    onBlur={handleBlur}
                    name={nameValueJobPost.jobType} value={values.jobType}
                    error={touched.jobType && errors.jobType}
                    previousValue={singleJob?.others?.jobType}
                />
                <LocationDropdown label="Shift"
                    values={[{ name: 'Morning Shift', _id: 'morningShift' }, { name: 'Day Shift', _id: 'dayShift' }, { name: 'Evening Shift', _id: 'eveningShift' }, { name: 'Night Shift', _id: 'nightShift' },]}
                    setFieldValue={setFieldValue}
                    onBlur={handleBlur}
                    name={nameValueJobPost.shiftType} value={values.shiftType}
                    error={touched.shiftType && errors.shiftType}
                    previousValue={singleJob?.others?.shiftType}
                />
            </div>

            <div className={styles.DISPLAYFLEX}>

                <div className={styles.DISPLAYFLEX_INNER}>
                    <ProfilePageInputfeild label="Salary Minimum" type="number" name={nameValueJobPost.min} value={values.min} onChange={handleChange} onBlur={handleBlur} error={touched.min && errors.min} />
                    <span>to</span>
                    <ProfilePageInputfeild label="Salary Maximum" type="number"
                        name={nameValueJobPost.max} value={values.max} onChange={handleChange} onBlur={handleBlur} error={touched.max && errors.max} />

                    <LocationDropdown label="Rate" values={[{ name: 'per hour' }, { name: 'per day' }, { name: 'per month' },]}
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        name={nameValueJobPost.rate} value={values.rate}
                        error={touched.rate && errors.rate}
                        previousValue={singleJob?.amount?.rate}
                    />

                </div>
                <div className={styles.DISPLAYFLEX_INNER}>
                    <ProfilePageInputfeild label="Start Date" type="date" name={nameValueJobPost.start} value={values?.start ? new Date(values?.start)?.toISOString()?.split('T')[0] : values?.start} onChange={handleChange} onBlur={handleBlur} error={touched.start && errors.start} />
                    <ProfilePageInputfeild label="End Date" type="date" name={nameValueJobPost.end} value={values?.end ? new Date(values?.end)?.toISOString()?.split('T')[0] : values?.end} onChange={handleChange} onBlur={handleBlur} error={touched.end && errors.end} />
                </div>
            </div>

            <div className={styles.DISPLAYFLEX}>
                <LocationDropdown label=" Country" values={location.countries} required={true} name={nameValueJobPost.location_country} value={values.location_country} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.location_country && errors.location_country}
                //  previousValue={profileDetails?.currentLocation?.currentLocation_country}
                />
                <LocationDropdown label="State" values={location.states} required={true} name={nameValueJobPost.location_state} value={values.location_state} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.location_state && errors.location_state}
                //  previousValue={profileDetails?.currentLocation?.currentLocation_country}
                />
            </div>

            <div className={styles.DISPLAYFLEX}>
                <LocationDropdown label="District" values={location.districts} required={true}
                    name={nameValueJobPost.location_district} value={values.location_district} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.location_district && errors.location_district}
                //  previousValue={profileDetails?.currentLocation?.currentLocation_country}
                />
                <LocationDropdown label="City" values={location.cities} required={true}
                    name={nameValueJobPost.location_city} value={values.location_city} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.location_city && errors.location_city}
                //  previousValue={profileDetails?.currentLocation?.currentLocation_country}
                />

            </div>


            <div className={styles.DISPLAYFLEX}>
                <ProfilePageInputfeild label="Email To" type="email" name={nameValueJobPost.emailTo} value={values.emailTo} onBlur={handleBlur} error={touched.emailTo && errors.emailTo} required={true} disabled={true} />

                <ProfilePageInputfeild label="Contact To" type="number"  name={nameValueJobPost.mobileTo} value={values.mobileTo} onChange={handleChange} onBlur={handleBlur} error={touched.mobileTo && errors.mobileTo} />

            </div>

            <div className={styles.DISPLAYFLEX}>
                <LocationDropdown label="Visibility" values={[{ name: 'Public', _id: 'public' }, { name: 'Private', _id: 'private' },]}
                    setFieldValue={setFieldValue}
                    onBlur={handleBlur}
                    name={nameValueJobPost.visibility} value={values.visibility}
                    error={touched.visibility && errors.visibility}
                    previousValue={singleJob?.others?.visibility}
                />

                <CascadingDropdown label={'Benefits'}
                    options={[{ name: 'Health insurance', _id: '1' }, { name: 'Work form home', _id: '2' }, { name: 'Paid time off', _id: '3' }, { name: 'Provident fund', _id: '4' }, { name: 'Life insurance', _id: '5' }, { name: 'Food provided', _id: '6' }, { name: 'Flexible schedule', _id: '7' }, { name: 'Other', _id: '8' }]}
                    required={true}
                    setFieldValue={setFieldValue}
                    name={nameValueJobPost.benifits}
                    onBlur={handleBlur}
                    error={touched.benifits && errors.benifits}
                    value={values?.benifits}

                />

                {/* <CascadingDropdown label={'Skills'} options={SkillsOptions} required={true} setFieldValue={setFieldValue} name={nameValueJobPost.skills} onBlur={handleBlur} error={touched.skills && errors.skills} value={values?.skills} /> */}
            </div>
            <div className={styles.DISPLAYFLEX}>
                <div className={`${styles.textareainput} `} >
                    <label htmlFor="">Description<span style={{ color: 'red' }}>*</span> </label>
                    {/* <textarea name="" id="" placeholder="Enter Description..." rows={4} ></textarea> */}

                    <ReactQuill
                        theme="snow"
                        name={nameValueJobPost.description}
                        value={values.description}
                        onChange={(e) => setFieldValue(nameValueJobPost.description, e)}
                        style={{ height: '100px' }}
                        className={styles.quill}

                    />


                </div>
            </div>

            <div className={`${styles.buttonwrapper} m-5`}>
                <Form.Check // prettier-ignore
                    type={'checkbox'}
                    id={`default-checkbox`}
                    label={`Make it Hot`}
                    name={nameValueJobPost.makeItHot}
                    checked={values?.makeItHot}
                    onChange={handleChange}

                />
                <button type="submit" onClick={handleSubmit}>Submit</button>
                <button type="" onClick={() => resetForm()} >Reset</button>
            </div>

        </div>
    )
}
export default JobPostForm