import React, { useState } from 'react';
import './Navigationbar.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavbarDropdown from './component/dropdown/NavbarDropdown';
import Image from 'react-bootstrap/Image';
import { navItems } from './navItems'
import { useDispatch, useSelector } from 'react-redux';
import { logoutAction } from '../../../Redux/actions/logoutAction';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// import { FaSignOutAlt } from "react-icons/fa";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { BServer } from '../../../server/backend';
import mainlogo from '../../../../src/assets/images/logo - Copy.png'

const Navigationbar = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const authentication = useSelector((state) => state?.authentication)
  const profileDetails = useSelector((state) => state?.myProfile)
  const profilePicture = profileDetails?.profileData?.personalInformation?.profilePicture
  // console.log('profileDetails in navbar', profileDetails)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const expand = 'md';

  const handleCloseOffcanvas = () => setShowOffcanvas(false);

  return (
    <Navbar expand={expand} className=" mb-0 hdrnav" style={{}} sticky="top">
      <Container fluid className='' style={{}}>
        {/* <Navbar.Brand className=''>Cariva Technologies</Navbar.Brand> */}
        <a href="/">

          <Image src={mainlogo}
            rounded width={200} height={28} style={{}} />
        </a>
        <Navbar.Toggle
          aria-controls={`offcanvasNavbar-expand-${expand}`}
          onClick={() => setShowOffcanvas((prev) => !prev)}
        />
        <Navbar.Offcanvas
          show={showOffcanvas}
          onHide={handleCloseOffcanvas}
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Image
              src={
                profilePicture
                  ? `${BServer}/uploads/${profilePicture}`
                  : "https://cdn-icons-png.flaticon.com/512/6596/6596121.png"
              }
              rounded width={40} height={40} style={{}} />
            <Offcanvas.Title className='header-links' id={`offcanvasNavbarLabel-expand-${expand}`}>
              Hi Meraj!
            </Offcanvas.Title>

          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">


              {navItems?.map((item, index) => {

                return returnEver(item, index, expand, handleCloseOffcanvas, authentication?.userRole, dispatch, navigate, profilePicture)

              }
              )

              }

            </Nav>

          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default Navigationbar;



function returnEver(item, index, expand, handleCloseOffcanvas, userRole, dispatch, navigate, profilePicture) {
  if (item.protected) {
    if (item.allowedRole.includes(userRole)) {
      if (item?.type === 'dropdown') {

        return (
          <NavbarDropdown handleCloseOffcanvas={handleCloseOffcanvas} expand={expand} title={item.title} items={item.dropdownItem} key={index} userRole={userRole} />



        )
      }
      if (item?.type === 'button' && item?.title !== 'Logout') {
        return (
          // <Nav.Link onClick={() =>{ 
          //   handleCloseOffcanvas()
          //   navigate(`${userRole!=='sAdmin'?userRole:'admin'}/${item.route}`)
          // } } key={index}>{item.title}</Nav.Link>
          <Link
            // to={`${userRole!=='sAdmin'?userRole:'admin'}/${item.route}`} 
            to={`${item.route}`}
            onClick={() => handleCloseOffcanvas()} className='navlinkss'>{item.title}</Link>
        )
      }
    }
    if (userRole && item.title === 'Logout') {
      return (
        <React.Fragment key={index}
        >
          {/* <NavDropdown
            title={'Account'}
            id={`offcanvasNavbarDropdown-expand-${expand}`}
            className='navDropdown_logout'
 >
            <NavDropdown.Item onClick={() =>
              dispatch(logoutAction(() => {
                navigate('/signin')
                toast.success('Logout Successfully')
              }))
            }>
              Logout
            </NavDropdown.Item>


          </NavDropdown> */}

          <Link
            // to={`${userRole!=='sAdmin'?userRole:'admin'}/${item.route}`} 
            to={`${item.route}`}
            onClick={() => {
              handleCloseOffcanvas()
              dispatch(logoutAction(() => {
                navigate('/signin')
                toast.success('Logout Successfully')
              }))
            }
            }
            className='navlinkss'>
            {/* {item.title} */}
            {/* <FaSignOutAlt /> */}
            <RiLogoutCircleRLine />

          </Link>
          <Image src={
            profilePicture
              ? `${BServer}/uploads/${profilePicture}`
              : "https://cdn-icons-png.flaticon.com/512/6596/6596121.png"
          } rounded width={35} height={35} className='nav_profilePicture' style={{ marginLeft: '15px' }} />
        </React.Fragment>

      )
    }
  } else {
    if (!userRole) {


      if (item?.title == 'Login') {
        return (

          <Nav.Link onClick={() => {
            handleCloseOffcanvas()
          }} key={index} href='/signin' className='navDropdown_login'>
            {/* <Link to={`${item.route}`} >
            {item.title}
          </Link> */}

            <span className='nav-link'>Login</span>

          </Nav.Link>

        )
      } else {
        return (
          // <Nav.Link onClick={() => { handleCloseOffcanvas() }} key={index} className='navDropdown_login'>
          <Link to={`${item.route}`} className='nav-link'>
            {item.title}
          </Link>
          // </Nav.Link>
        )
      }


    }
  }

}











