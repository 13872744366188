import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './ResetPassword.css'
import axios from 'axios'
import { BServer } from '../../../server/backend';
import { onUploadProgress } from '../../../services/axios/onUploadProgress';
import { fileUpload } from '../../../Redux/actions/fileUpload/fileUpload';
const ResetPassword = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const store = useSelector((state) => state?.onUploadProgress)
  const dispatch=useDispatch()
  const handleFileChange = (event) => {
    // setSelectedFile(event.target.files[0]);
    dispatch(fileUpload(event))
  };
  console.log('selectedFile', selectedFile)
  const handleUpload = () => {
    if (selectedFile) {
      // Create a FormData object
      const formData = new FormData();
      formData.append('file', selectedFile, selectedFile.name);

      // Make a POST request using Axios
      axios.post(`${BServer}/uploadeone`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          // Include any other headers required by your API
        }, onUploadProgress: onUploadProgress
      })
        .then(response => {
          console.log('File uploaded successfully:', response.data);
        })
        .catch(error => {
          console.error('Error uploading file:', error);
        });
    } else {
      console.log('No file selected.');
    }
  };

  return (
    <div className="row col-12 register m-0 p-0">

      <div className="col-md-4">



      </div>



      <div className="col-md-6 reset-pswd">

        <p className='main-title'>Change Password</p>
        <input type="file" onChange={handleFileChange} name='cariva_file'/>
        <button onClick={handleUpload}>Upload File</button>
        <div className='align-items-center justify-content-center text-align-center'>
          <p className='pswd-hd mb-0'>Current Passowrd</p>
          <input type="password" name="password" className='pswd-fld'></input>

          {/* <p className='mb-0 pswd-hd'>Password Requirements:</p>
                <p className='require-text'>
                - Minimum 8 characters<br/>
                - Maximum 50 characters(s)<br/>
                - At least 1 lowercase letter(s)<br/>
                - At least 1 uppercase letter(s)<br/>
                - At least 1 digit(s)
                </p> */}


          <p className='pswd-hd mb-0'>New Passowrd</p>
          <input type="password" name="password"></input>
          <p className='pswd-hd mb-0'>Confirm Passowrd</p>
          <input type="password" name="password"></input><br />
          <button type="button" class="btn-reset">Submit</button>
        </div>




        <div className="">


        </div>
      </div>





      <div className="col-md-3">

      </div>


    </div>





  )
}

export default ResetPassword
