import React from "react";
import "./UnitJobView.css";
import hotjobs from "../../../assets/images/fire-hot.png";
import { MdOutlineModeEdit, MdDeleteOutline } from "react-icons/md";
import { IoEye } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteJobAction } from "../../../Redux/actions/job/deleteJobAction";
import { globalModal } from "../../../Redux/actions/globalModal";
import { applyToSingleJobAction } from "../../../Redux/actions/job/applyToSingleJobAction";
import { toast } from "react-toastify";

const UnitJobView = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appliedJobs_idArray, shortlistedJobs_idArray } = useSelector(
    (state) => state?.appliedJob
  );
  const authentication = useSelector((state) => state?.authentication);
  const applyJobHandle = (props) => {
    if (authentication?.token) {
      dispatch(applyToSingleJobAction(props));
    } else {
      // dispatch(globalModal({ component: 'ContactForm', closeButton: true, size: 'lg' }))
      navigate("/signin");
    }
  };

  return (
    <div className="card ">
      <div className="row">
        <div className="padd">
          <div className="padd1">
            <span
              id="hd"
              onClick={() =>
                dispatch(
                  globalModal({
                    component: "JobDetails",
                    closeButton: true,
                    size: "xl",
                    data: data,
                  })
                )
              }
            >
              {data?.basic?.title?.slice(0, 65)}
              {data?.basic?.title?.length > 65 && `...`}
            </span>

            {data?.others?.makeItHot && <img src={hotjobs} alt="hotjobs" />}
          </div>
          {(authentication?.userRole === "admin" ||
            authentication?.userRole === "sAdmin") && (
            <div className="padd2 sAdminView ">
              <p>
                <span
                  onClick={() =>
                    navigate(
                      `applied-user/${data?._id}/${data?.jobId}/developer`
                    )
                  }
                >
                  Applied by developer: {data?.applied?.byDeveloper?.count0}
                </span>
                <span
                  onClick={() =>
                    navigate(`applied-user/${data?._id}/${data?.jobId}/agency`)
                  }
                >
                  Applied by agency: {data?.applied?.byAgency?.count0}
                </span>
              </p>
            </div>
          )}
          {/* <div className="padd2">
              <MdOutlineModeEdit size={"20px"} onClick={() => navigate('edit', { state: { _id: data?._id, jobId: data?.jobId } })} />
              <IoEye size={"20px"} onClick={() => dispatch(deleteJobAction('VISIBILITY', data?._id))} />
              <MdDeleteOutline size={"20px"} onClick={() => dispatch(deleteJobAction('DELETE', data?._id))} />
            </div> */}
        </div>
      </div>

      <div className="mid mt-1">
        <div className="mid-dist">
          <span className="range-sal">
            <b>₹</b> {data?.amount?.min}- <b>₹</b> {data?.amount?.max}{" "}
            {data?.amount?.rate?.name}
          </span>
          <span className="range-sal">
            <b>Type: </b>
            {data?.others?.jobType?.name}
          </span>
          <span className="range-sal">
            <b>Exp:</b> {data?.profession?.totalExperienceInYear?.name}+ years
          </span>
          <span className="range-sal">
            <b>No. of opening:</b> {data?.basic?.openings}
          </span>
        </div>
        {/* <span className='range-sal'>2 resources</span> */}
        {authentication?.userRole == "sAdmin" ||
        authentication?.userRole == "admin" ? (
          authentication.userId == data?.createdBy && (
            <div className="padd2" style={{ cursor: "pointer" }}>
              <MdOutlineModeEdit
                size={"20px"}
                onClick={() =>
                  navigate("edit", {
                    state: { _id: data?._id, jobId: data?.jobId },
                  })
                }
              />
              {/* <IoEye size={"20px"} onClick={() => dispatch(deleteJobAction('VISIBILITY', data?._id))} /> */}
              <MdDeleteOutline
                size={"20px"}
                onClick={() =>
                  dispatch(
                    deleteJobAction("DELETE", data?._id, (props) =>
                      toast.success(props)
                    )
                  )
                }
              />
            </div>
          )
        ) : (
          <button
            onClick={() =>
              applyJobHandle({ _id: data?._id, jobId: data?.jobId })
            }
            disabled={appliedJobs_idArray?.includes(data?._id)}
            style={{
              cursor: appliedJobs_idArray?.includes(data?._id)
                ? "not-allowed"
                : "pointer",
            }}
          >
            {shortlistedJobs_idArray?.includes(data?._id)
              ? "Shortlisted"
              : appliedJobs_idArray?.includes(data?._id)
              ? "Applied"
              : "Apply Now"}
          </button>
        )}
      </div>
      <p className="pt-3 shrt-hd mb-1">Job Description</p>
      <>
        <span
          className="jd-des mb-0 "
          dangerouslySetInnerHTML={{
            __html: data?.basic?.description?.slice(0, 280),
          }}
        />

        {/* <span className='read_more'
          onClick={() => dispatch(globalModal({ component: 'JobDetails', closeButton: true, size: 'xl', data: data }))}
          style={{ color: 'blue', fontSize: '14px', cursor: 'pointer' }}
        >
          Read more
        </span> */}
      </>

      <div className="last ">
        <div className="last1">
          <p className=" shrt-hd ">Skill Requirement</p>
          <div className="skills">
            {data?.profession?.skills &&
              data?.profession?.skills
                ?.slice(0, 4)
                ?.map((item) => <span className="skil">{item?.name}</span>)}

            {/* <span className='skil'>HTML/CSS</span>
            <span className='skil'>Databases</span>
            <span className='skil'>DevOps</span>
            <span className='skil'>Git</span> */}
            {data?.profession?.skills?.length > 4 && (
              <span className="skil">
                {`+ ${data?.profession?.skills?.length - 4}`}
              </span>
            )}
          </div>
        </div>
        <p className="adrss ">
          {/* <FontAwesomeIcon icon="fa-solid fa-location-dot" /> */}
          <span>
            {" "}
            <span className="highlightspan"> Job Id:</span> {data?.jobId}
          </span>
          <p className="datess ">
            <span className="highlightspan">Location:&nbsp;</span>
            {`${data?.location?.location_city?.name}, ${data?.location?.location_state?.name}`}
          </p>
          <p className="datess">
            <span className="highlightspan">Posted on:&nbsp;</span>{" "}
            {new Date(data?.interval?.start)?.toDateString()} &nbsp;&nbsp;
            <span className="highlightspan"> Ends on:&nbsp;</span>{" "}
            {new Date(data?.interval?.end)?.toDateString()}
          </p>
        </p>
      </div>
    </div>
  );
};

export default UnitJobView;
