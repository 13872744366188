import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { IoMdClose } from "react-icons/io";
import OtpInput from "react-otp-input"
import { useDispatch, useSelector } from 'react-redux';
import { sentOtp, setOtpOnMobileAction, verifyOTPAndLogin, verifyOtpFromMobile } from '../../../../../Redux/actions/verification/setOtpOnMobileAction';
import styles from "../verifyMobile/verifyMobile.module.css"
import { useNavigate } from 'react-router-dom';

const LoginWithOtp = () => {

    const data = useSelector((state) => state?.globalModal?.data)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [otpStatus, setOtpStatus] = useState(false)
    const [seconds, setSeconds] = useState(null);
    const [OTP, setOTP] = useState('')
    const otpSchema = Yup.string()
        .matches(/^\d{6}$/, 'OTP must be exactly 6 digits')
        .required('OTP is required');
    const [otpError, setOtpError] = useState('');

    const { values, handleChange, handleBlur, handleSubmit, resetForm, errors } = useFormik({
        initialValues: {
            mobile: "",

        },
        validationSchema: Yup.object().shape({
            mobile: Yup.string()
                .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
                .required('Mobile number is required'),

            // OTP: Yup.string()
            //   .matches(/^\d{6}$/, 'OTP must be exactly 6 digits')
            //   .required('OTP is required')
        }),
        onSubmit: values => {
            dispatch(sentOtp(values?.mobile,
                (props) => {
                    setOtpStatus(props.status)
                    setSeconds(120)
                    setOTP('')
                }
            ))
            setOTP('')
        },
        onReset: values => {
            setOTP('')
        }

    });

    console.log('errors', errors)
    console.log('values', values)
    console.log('OTP', OTP)

    const onChangeHandler = (newOTP) => {
        setOTP(newOTP)

        otpSchema.validate(newOTP)
            .then(() => {
                setOtpError('');
            })
            .catch((error) => {
                setOtpError(error.message);
            });
    }


    const handleResend = () => {
        setOTP('')
        if (otpStatus && !seconds) {
            handleSubmit()
        } else {
            return -1
        }
    }

    const handleVerify = () => {
        if (otpError || !OTP) {
            return -1
        }
        dispatch(verifyOTPAndLogin(OTP, values.mobile, (path) => {
            console.log('path', path)
            navigate(path)


        }))
        setOTP('')

    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (seconds > 0) {
                setSeconds(prevSeconds => prevSeconds - 1);
            } else if (seconds == 0) {
                setSeconds(null)
                // setOtpStatus(false)
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [seconds]);

    // useEffect(() => {
    //   resetForm()
    // }, [data?.message]);
    // if (!message) {
    //   resetForm()
    // }
    console.log("VerifyMobile 96", data?.message);


    return (
        <div className={styles.VerfiyMobileCont} >
            {!otpStatus &&
                <>
                    <div className={styles.closebutton} onClick={() => dispatch({ type: 'HIDE_MODAL' })} ><IoMdClose size={"24px"} /></div>
                    <h3>Verify your mobile</h3>
                    <div className={styles.phoneinput}>
                        <label htmlFor="phoneno" >Enter Phone Number</label> <br />
                        <input type="number" id='phoneno' name='mobile' value={values.mobile} onChange={handleChange} onBlur={handleBlur} disabled={otpStatus}

                            style={{ cursor: otpStatus && 'not-allowed' }}
                        />
                    </div>

                    <button className={styles.sendOTP} onClick={handleSubmit}
                        disabled={otpStatus}
                        style={{ cursor: otpStatus && 'not-allowed', background: '#18042B' }}
                    >
                        Send OTP
                    </button>
                </>
            }

            {otpStatus &&
                <>
                    <div className={styles.VerifynumberOTP}>
                        <label>Enter OTP</label>
                        <OtpInput
                            inputStyle={styles.OTPClass}
                            containerStyle={styles.dispayflex}
                            numInputs={6}
                            renderSeparator={<span>-</span>}
                            name='OTP'
                            value={OTP}
                            onChange={onChangeHandler}
                            renderInput={(props) => <input {...props} />}
                        />
                    </div>
                    <div style={{ width: "100%", textAlign: 'center' }}  >
                        {data?.message && <span style={{ color: "red", fontSize: "12px", fontWeight: "300", textAlign: 'center' }} >{data.message}</span>}
                        <div className={styles.verifyMobileActions}>
                            {/* <a href="/"></a> */}
                            <span
                                onClick={handleResend}
                                style={{ cursor: (otpStatus && !seconds) ? 'pointer' : 'not-allowed' }}

                            >Resend OTP</span>
                            <span style={{ fontSize: "16px", fontWeight: "300" }} >{seconds} sec</span>
                        </div>
                    </div>

                    <button onClick={handleVerify}>Verify</button></>
            }


        </div>

    )
}

export default LoginWithOtp